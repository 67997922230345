@import '@/common.scss';

.pg-recharge_send_medal .cmp-tabs {
  margin-top: px2rem(18px);
  display: flex;
  justify-content: space-between;
  padding: 0 px2rem(8px);
  position: relative;
  margin-bottom: px2rem(17px);

  &::after {
    content: '';
    position: absolute;
    width: px2rem(350px);
    height: px2rem(5px);
    bottom: px2rem(34px);
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, rgba(111, 63, 9, 1), rgba(241, 193, 28, 1), rgba(109, 64, 8, 1));
  }

  &-item {
    width: px2rem(110px);
    height: px2rem(90px);
    background-image: url(~@RechargeSendMedal/images/rewards-tab.webp);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: px2rem(12px);
    font-weight: bold;
    color: #FFD80D;
    z-index: 2;

    .icon {
      width: px2rem(42px);
      height: px2rem(26px);
      object-fit: cover;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: px2rem(23px);
    }

    .interval {
      margin-top: auto;
      margin-bottom: px2rem(6px);
      display: flex;
      align-items: center;

      &>img {
        width: px2rem(14px);
        height: px2rem(14px);
        object-fit: cover;
      }

      &>span {
        margin: 0 px2rem(2px);
      }
    }

    &.selected {
      width: px2rem(113px);
      height: px2rem(129px);
      background-image: url(~@RechargeSendMedal/images/rewards-tab-ac.webp);

      .icon {
        width: px2rem(52px);
        height: px2rem(31px);
        top: px2rem(30px);
      }

      .interval {
        margin-bottom: px2rem(34px);
        color: #6E1918;
      }
    }
  }

}