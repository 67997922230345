@import '@/common.scss';

.pg-luckygift .cmp-records {

    &__dialog {
        &-list {

            table {
                width: 100%;
                border-radius: px2rem(6px);
                border: px2rem(2px) solid rgba(255, 219, 0, 1);

                thead {
                    tr {
                        height: px2rem(25px);
                        display: table;
                        width: 100%;
                        table-layout: fixed;

                        th {
                            font-size: px2rem(14px);
                            color: #FFD100;
                            background-color: rgba(0, 0, 0, 0.3);
                        }
                    }
                }

                tbody {
                    max-height: px2rem(225px);
                    display: block;
                    overflow-y: scroll;

                    tr {
                        height: px2rem(25px);
                        display: table;
                        width: 100%;
                        table-layout: fixed;

                        td {
                            font-size: px2rem(12px);
                            color: #fff;

                            img {
                                width: px2rem(15px);
                                height: px2rem(15px);
                                vertical-align: middle;
                            }

                            span {
                                margin: 0 px2rem(2px);
                            }
                        }

                        &:nth-child(2n+1) {
                            background-color: rgba(0, 0, 0, 0.1);
                        }

                        &:nth-child(2n) {
                            background-color: rgba(0, 0, 0, 0.2);
                        }


                    }
                }


            }
        }

    }
}