@import '@/common.scss';

.pg-invite {
  .tabs {
    margin: px2rem(35px) 0 px2rem(30px) 0;
    display: flex;
    justify-content: center;
    &-item {
      width: px2rem(123px);
      height: px2rem(41px);
      text-align: center;
      font-size: px2rem(12px);
      font-weight: bold;
      color: #65b232;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(~@Invite/images/tab-gray-bg.webp);
      background-repeat: no-repeat;
      background-size: contain;
      span {
        display: block;
        width: px2rem(102px);
      }
      &.selected {
        color: #e2ff00;

        background-image: url(~@Invite/images/tab-bg.webp);
      }

      &.isRankTabs {
        width: px2rem(105px);
        height: px2rem(40px);
        background-image: url(~@Invite/images/tab-bg-rank-gray.webp);
        span {
          width: px2rem(57px);
        }
        margin: 0 px2rem(4px);
        &.selected {
          background-image: url(~@Invite/images/tab-bg-rank.webp);
        }
      }
    }
    &.no-margin {
      margin: unset;
    }
  }
}
