@import '~@/common.scss';

.pg-recharge_send_medal .cmp-mine {
    width: 100%;
    height: px2rem(119px);
    background: url(~@RechargeSendMedal/images/mine-bg.webp) no-repeat center/cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 px2rem(17px);
    box-sizing: border-box;
    padding-top: px2rem(25px);
    margin-bottom: px2rem(20px);

    &-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: px2rem(10px);
        font-size: px2rem(16px);
        font-weight: bold;
        color: #F9F2B4;
        text-shadow: 0px px2rem(2px) px2rem(2px) #813E09;
    }

    &>.left {
        display: flex;
        align-items: center;

        img {
            width: px2rem(50px);
            height: px2rem(50px);
            border: px2rem(1px) solid #A77633;
            border-radius: 50%;
        }

        .info {
            display: flex;
            flex-direction: column;
            margin: 0 px2rem(10px);

            &>span:first-child {
                font-size: px2rem(14px);
                font-weight: bold;
                color: #FFED8C;
                margin-bottom: px2rem(4px);
            }

            &>span:last-child {
                font-size: px2rem(12px);
                color: rgba($color: #FFED8C, $alpha: 0.6);
            }
        }
    }

    &>.right {
        font-size: px2rem(12px);
        font-weight: bold;
        color: #FFED8C;
        display: flex;
        align-items: center;

        img {
            width: px2rem(11px);
            height: px2rem(11px);
            object-fit: cover;
            margin: 0 px2rem(4px);
        }
    }
}