@import '~@/common.scss';
@import '../../var.scss';

.#{$prefix-cls} .cmp-danmaku-item {
  background-image: url('./images/swiper.webp');
  background-repeat: no-repeat;
  background-size: cover;
  width: px2rem(177px);
  height: px2rem(30px);
  padding-left: px2rem(5px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: px2rem(22px) 0 0 px2rem(22px);
  overflow: hidden;

  img {
    width: px2rem(22px);
    height: px2rem(22px);
    object-fit: fill;
    border-radius: 50%;
    margin-right: px2rem(4px);
  }

  span {
    flex: 1;
    color: white;
    font-size: px2rem(12px);

    strong {
      color: rgba(255, 181, 0, 1);
    }
  }
}