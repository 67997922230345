@import '@/common.scss';

.cmp-tab-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-btn {
    display: flex;
    justify-content: space-between;
    z-index: 2;
    padding: 0 px2rem(8px);
    box-sizing: border-box;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      //以下依据需求更改,样式覆盖
      // width: px2rem(170px);
      flex: 1;
      height: px2rem(47px);
      color: rgba(255, 236, 0, 1);
      font-size: px2rem(19px);
      opacity: 0.6;
      margin: 0 px2rem(4px);
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__text {
      //以下依据需求更改,样式覆盖
      // margin-top: px2rem(4px);
    }

    .selected {
      opacity: 1;
    }
  }

  &-children {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
  }


}

// .mirror {
//   .cmp-tab-buttons {
//     &-btn {
//       &__item {
//         &:first-child {
//           margin-right: unset;
//           margin-left: px2rem(17px);
//         }
//       }
//     }
//   }
// }