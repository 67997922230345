@import '@/common.scss';

.pg-invite {
  .extact-golds {
    &-dialog {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #000000, $alpha: 0.6);
      .body {
        width: px2rem(356px);
        height: px2rem(324px);
        background: url(~@Invite/images/extact-golds-bg.webp);
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0 px2rem(20px);
        box-sizing: border-box;
        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: px2rem(13px);
          margin-bottom: px2rem(46px);
          .left__line,
          .right__line {
            width: px2rem(66px);
            height: px2rem(8px);
          }
          .left__line {
            transform: rotateY(180deg);
          }
          span {
            font-size: px2rem(14px);
            font-weight: 600;
            color: #f0ff00;
            margin: 0 px2rem(8px);
          }
        }
        .title {
          font-size: px2rem(14px);
          font-weight: bold;
          color: #ffffff;
          margin-bottom: px2rem(17px);
        }

        input {
          width: px2rem(316px);
          height: px2rem(35px);
          border-radius: px2rem(16px);
          background-color: #002905;
          border: solid px2rem(1px) #ffb900;
          font-size: px2rem(12px);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: px2rem(17px);
          padding: 0 px2rem(12px);
          box-sizing: border-box;
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        .can-extact-total {
          position: relative;
          margin-bottom: px2rem(17px);
          img {
            width: px2rem(13px);
            height: px2rem(13px);
            position: absolute;
            top: px2rem(11px);
            left: px2rem(12px);
          }
          input {
            padding-left: px2rem(31px);
          }
        }
        .extact-count {
          position: relative;

          &-auto__input {
            font-size: px2rem(12px);
            font-weight: 600;
            color: #f0ff00;
            text-decoration: underline;
            position: absolute;
            top: px2rem(10px);
            right: px2rem(12px);
          }
        }
        .operate {
          display: flex;
          justify-content: center;
          margin-top: px2rem(40px);
          .confirm-btn {
            width: px2rem(147px);
            margin: 0 px2rem(13px);
          }
        }
      }
    }
  }
}

.mirror .pg-invite {
  .extact-golds {
    &-dialog {
      .body {
        .header {
          direction: ltr;
        }
      }
    }
    .extact-count-auto__input {
      right: unset;
      left: px2rem(12px);
    }
  }
}
