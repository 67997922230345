@import '@/common.scss';

.pg-recharge_send_vip {
    display: flex;
    flex-direction: column;
    background: #711313;

    img {
        width: 100%;
        object-fit: cover;

        &:nth-child(2) {
            margin-top: px2rem(-65px);
        }

        &:nth-child(3) {
            margin-top: px2rem(8px);
        }
    }

    .cmp-bottom-statement {
        padding: px2rem(7px) px2rem(46px) px2rem(12px);
    }
}