@import '@/common.scss';

.pg-national {
  .tabs {
    margin-top: px2rem(18px);
    display: flex;
    justify-content: space-between;
    padding: 0 px2rem(8px);
    &-item {
      width: px2rem(170px);
      height: px2rem(47px);
      text-align: center;
      font-size: px2rem(19px);
      font-weight: bold;
      color: rgba($color: #FFEC00, $alpha: 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(~@National/images/tab-gray-bg.webp);
      background-repeat: no-repeat;
      background-size: contain;
      &.selected {
        color: #FFEC00;

        background-image: url(~@National/images/tab-bg.webp);
      }
    }
    &.no-margin {
      margin: unset;
    }
  }
}
