@import '@/common.scss';

.pg-invite .bind_invitation {
  position: relative;
  width: px2rem(365px);
  height: px2rem(473px);
  background-image: url(~@Invite/images/bind-invitation-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 px2rem(5px);
  padding-top: px2rem(67px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .spin-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    font-size: px2rem(15px);
    font-weight: bold;
    color: #f0ff00;
    position: absolute;
    top: px2rem(10px);
    left: px2rem(136px);
  }
  .common-tip {
    font-size: px2rem(11px);
    font-weight: 600;
    color: #f0ff00;
    width: 100%;
    text-align: center;
    margin-bottom: px2rem(30px);
  }
  &-select__tip {
    display: flex;
    align-items: baseline;
    .common-tip {
      margin: 0 px2rem(9px) px2rem(30px) px2rem(9px);
    }
    .diamond {
      min-width: px2rem(7px);
      min-height: px2rem(7px);
      background-color: #7e9f00;
      transform: rotate(45deg);
      box-sizing: content-box;
    }
  }

  input {
    width: px2rem(295px);
    height: px2rem(35px);
    border-radius: px2rem(16px);
    background-color: #002905;
    border: solid px2rem(1px) #ffb900;
    font-size: px2rem(12px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: px2rem(17px);
    padding: 0 px2rem(12px);
    box-sizing: border-box;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .confirm-btn {
    margin: px2rem(25px) 0 px2rem(40px) 0;
    &.disabled {
      background-color: #75946c;
      border: none;
      color: #dbdfbf;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    margin-bottom: px2rem(55px);
    margin-top: px2rem(10px);
    img {
      width: px2rem(70px);
      height: px2rem(70px);
      border-radius: 50%;
      margin: 0 px2rem(15px);
      border: px2rem(2px) solid rgb(245, 184, 0);
      box-sizing: border-box;
    }
    &-nick {
      p {
        margin: px2rem(4px) 0;
        font-weight: bold;
      }
      p:nth-child(1) {
        font-size: px2rem(14px);
        color: #ffffff;
      }
      p:nth-child(2) {
        font-size: px2rem(12px);
        color: rgb(158, 181, 161);
      }
    }
  }
  .blind-boxs {
    img {
      width: px2rem(109px);
      height: px2rem(107px);
    }
    .my-choose {
      display: flex;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 px2rem(33px);
        .bg {
          width: px2rem(87px);
          height: px2rem(90px);
          background-image: url(~@Invite/images/dialog-reward-item-bg.webp);
          background-repeat: no-repeat;
          background-size: contain;

          img {
            width: px2rem(57px);
            height: px2rem(57px);
            margin: px2rem(17px) px2rem(15px);
          }
        }
        span {
          display: block;
          font-size: px2rem(11px);
          font-weight: 600;
          color: #d7ecbb;
          margin-top: px2rem(8px);
        }
      }
    }
  }
  .bottom-tip {
    color: #fff;
    margin: 0;
    margin-top: px2rem(25px);
  }

  .get__dialog {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    &-body {
      width: px2rem(365px);
      height: px2rem(352px);
      background-image: url(~@Invite/images/get-dialog-bg.webp);
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      padding-top: px2rem(101px);
      &.ar {
        background-image: url(~@Invite/images/get-dialog-bg-ar.webp);
      }
      .pre-choose {
        display: flex;
        justify-content: center;
        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 px2rem(33px);
          .bg {
            width: px2rem(97px);
            height: px2rem(100px);
            background-image: url(~@Invite/images/dialog-reward-item-bg.webp);
            background-repeat: no-repeat;
            background-size: contain;

            img {
              width: px2rem(57px);
              height: px2rem(57px);
              margin: px2rem(19px) px2rem(17px);
            }
          }
          span {
            display: block;
            font-size: px2rem(11px);
            font-weight: 600;
            color: #d7ecbb;
            margin-top: px2rem(8px);
          }
        }
      }

      .operate {
        display: flex;
        justify-content: center;
        margin-top: px2rem(40px);
        .confirm-btn {
          width: px2rem(147px);
          margin: 0 px2rem(13px);
        }
      }
    }
  }
}

.mirror .pg-invite .bind_invitation {
  .blind-boxs {
    flex-direction: row-reverse;
  }
}
