@import '~@/common.scss';

.pg-ramadan-answer .cmp-rewards {
    display: flex;
    justify-content: center;

    img {
        width: px2rem(364px);
        height: px2rem(658px);
        object-fit: cover;
    }
}