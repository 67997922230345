@import '~@/common.scss';

.pg-game-rankings .cmp-rewards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: px2rem(568px);
    overflow: auto;
    box-sizing: border-box;

    &__item {
        width: px2rem(318px);
        background: rgba(85, 29, 12, 0.68);
        border-radius: px2rem(10px);
        position: relative;
        padding: px2rem(15px);
        box-sizing: border-box;
        margin-bottom: px2rem(12px);
        text-align: center;

        >div {
            width: 100%;
        }

        &-title {
            font-weight: bold;
            font-size: px2rem(16px);
            color: #FFE100;
        }

        &-sub {
            font-size: px2rem(12px);
            margin-top: px2rem(10px);
            color: rgba($color: white, $alpha: .6);
        }

        &-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            &>div {
                width: px2rem(80px);
                height: px2rem(80px);
                background: url(~@GameRankings/images/rewards-icon-bg.webp) no-repeat center/cover;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: px2rem(66px);
                    height: px2rem(66px);
                    object-fit: cover;
                }
            }

            &>span {
                font-weight: bold;
                font-size: px2rem(11px);
                color: #F8E38C;
                margin-top: px2rem(10px);
            }
        }
    }

    &__items {
        margin-top: px2rem(15px);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-shrink: 0;
    }
}