@import '@/common.scss';

.pg-invite {
  & .detail-list {
    height: px2rem(339px);
    overflow-y: auto;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 px2rem(21px);
      margin-top: px2rem(15px);
      &-left {
        display: flex;
        align-items: center;
        img {
          width: px2rem(45px);
          height: px2rem(45px);
          border-radius: 50%;

          border: px2rem(2px) solid rgb(245, 184, 0);
        }
        &-nick {
          margin: 0 px2rem(15px);
          p {
            margin: px2rem(4px) 0;
            font-weight: bold;
          }
          p:nth-child(1) {
            font-size: px2rem(14px);
            color: #ffffff;
          }
          p:nth-child(2) {
            font-size: px2rem(12px);
            color: rgb(158, 181, 161);
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;

        img {
          width: px2rem(13px);
          height: px2rem(13px);
          margin: 0 px2rem(4px);
        }
        span {
          font-size: px2rem(14px);
          font-weight: bold;
          color: #fee34c;
        }
        .coin {
          display: flex;
          align-items: center;
        }
        .time {
          font-size: px2rem(12px);
          font-weight: bold;
          color: #9fb7a2;
        }
        &.gold-detail {
          align-items: flex-end;
          flex-direction: column;
        }
      }
    }
    &.gold-detail-list {
      margin-top: px2rem(18px);
      height: px2rem(386px);
    }
  }
}
