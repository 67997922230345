@import '@/common.scss';

.pg-invite .cmp-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: px2rem(96px);
    height: px2rem(76px);
    margin-bottom: px2rem(10px);
  }
  span {
    font-size: px2rem(16px);
    font-weight: 600;
    color: #557950;
  }
}
