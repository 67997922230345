@import '@/common.scss';

.pg-recharge_send_medal {
    width: px2rem(375px);
    overflow-x: hidden;
    background-color: rgba(35, 3, 6, 1);
    position: relative;
    min-height: 100vh;

    &>.banner {
        width: 100%;
    }

    main {
        padding: 0 px2rem(10px);
        margin-top: px2vw(-450px);
    }
}

.mirror .pg-recharge_send_medal {
    direction: rtl;
}