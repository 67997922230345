@import '@/common.scss';

.cmp-empty {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: px2rem(96px);
    height: px2rem(76px);
  }

  span {
    font-size: px2rem(12px);
    font-weight: 600;
    color: rgba($color: #EAEAEA, $alpha: 0.4);
  }
}