@import '@/common.scss';

.pg-luckygift .cmp-records {
  position: absolute;
  top: px2rem(253px);
  left: 0;
  width: px2rem(67px);
  height: px2rem(30px);
  text-align: center;
  line-height: px2rem(30px);
  font-size: px2rem(12px);
  color: #f0ff00;
  display: flex;
  justify-content: center;
  align-items: center;

  &__bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: scaleX(-1);
  }

  span {
    position: absolute;
    left: px2rem(10px);
    z-index: 1;
    font-size: px2rem(12px);
    color: #f0ff00;
    font-weight: bold;
  }

  &__dialog {

    &-title {
      width: px2rem(76px);
      height: px2rem(13px);
      position: absolute;
      left: 50%;
      top: px2rem(86px);
      transform: translateX(-50%);
      color: #fff;
    }

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: px2rem(405px);
      background-image: url(~@LuckyGift/images/lucky-chest-bg.webp);
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
    }

    &-list {
      margin: px2rem(125px) px2rem(36px) 0;
    }

    .records-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.mirror .pg-luckygift .cmp-records {
  left: unset;
  right: 0;

  &__bg {
    transform: unset;
  }

  span {
    left: unset;
    right: px2rem(10px);
  }


}