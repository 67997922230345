@import '~@/common.scss';

.pg-charm-anchor {
    width: px2rem(375px);
    position: relative;
    background-color: rgba(57, 33, 18, 1);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: px2rem(80px);

    header {
        width: 100%;

        img {
            width: 100%;
            object-fit: cover;
        }

        .gift-img {
            margin-top: px2rem(-120px);
        }
    }

    .cmp-tab-buttons {
        &-btn {
            &__item {
                opacity: unset;
                background-image: url(~@CharmAnchor/images/tab.webp);
                background-size: contain;
                height: px2rem(33px);
                font-size: px2rem(14px);
                font-weight: bold;
                color: #E8BD89;
            }

            .selected {
                background-image: url(~@CharmAnchor/images/tab-ac.webp);

                &>span {
                    color: #BE4800;
                    margin-top: px2rem(2px);
                }
            }
        }

        &-children {
            background-image: url(~@CharmAnchor/images/rank-bg.webp);
            height: px2rem(872px);
            background-size: 100% 100%;
            margin-top: px2rem(13px);
            padding: 0 px2rem(22px);
            padding-top: px2rem(80px);
            position: relative;
        }
    }

    .tab-reward .cmp-tab-buttons {
        &-children {
            background-image: url(~@CharmAnchor/images/rank-bg-long.webp);
            height: px2rem(1030px);
        }
    }

    &-rank-title {
        position: absolute;
        font-size: px2rem(14px);
        font-weight: bold;
        color: #FCE548;
        left: 50%;
        transform: translateX(-50%);
        top: px2rem(43px);
    }

    &-reward {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 px2rem(10px);

        img {
            height: px2rem(451px);
            object-fit: cover;

            &:last-child {
                margin-top: px2rem(15px);
            }
        }
    }
}

.mirror .pg-charm-anchor {
    direction: rtl;
}