@import '@/common.scss';

.cmp-dialog {
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* 以下根据需求修改，覆盖样式 */
    // background-image: url('~@RechargeSendMedal/images/dialog-bg.webp');
    width: px2rem(355px);
    height: px2rem(415px);
  }

  &__title {
    position: absolute;
    font-weight: bold;
    /* 以下根据需求修改，覆盖样式 */
    top: px2rem(10px);
    font-size: px2rem(16px);
    color: #F9F2B4;
    // text-shadow: 0px px2rem(2px) px2rem(2px) #813E09;
  }

  &__close {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    /* 以下根据需求修改，覆盖样式 */
    background-image: url(~@/images/dialog-close.png);
    width: px2rem(33px);
    height: px2rem(33px);
    right: 0;
    top: px2rem(-18px);
  }
}

.mirror .cmp-dialog {
  &__close {
    right: unset;
    left: 0;
  }
}