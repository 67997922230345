@import '@/common.scss';

.pg-national .cmp-rule {
  position: absolute;
  top: px2rem(232px);
  right: 0;
  width: px2rem(45px);
  height: px2rem(22px);
  text-align: center;
  line-height: px2rem(33px);
  font-size: px2rem(12px);
  color: #f0ff00;
  display: flex;
  justify-content: center;
  align-items: center;

  &__bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  span {
    position: relative;
    left: px2rem(6px);
    z-index: 1;
    font-size: px2rem(11px);
    color: #f0ff00;
  }

  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.6);

    &-title {
      position: absolute;
      left: 50%;
      top: px2rem(71px);
      transform: translateX(-50%);
      color: #fff;
    }

    &-close {
      width: px2rem(24px);
      height: px2rem(24px);
      position: absolute;
      right: px2rem(15px);
      top: px2rem(63px);
    }

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: px2rem(465px);
      background-image: url(~@National/images/dialog-bg.webp);
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center;
    }

    &-list {
      display: inline-block;
      margin: px2rem(106px) px2rem(15px) 0;
      text-align: left;
      font-size: px2rem(12px);
      color: #fff;
    }

    &-info {
      margin-bottom: px2rem(15px);
    }

    &-item:not(:last-child) {
      margin-bottom: px2rem(15px);
    }

    &-arrow {
      width: px2rem(66px);
      position: absolute;
      left: px2rem(82px);
      top: px2rem(18px);

      &:nth-child(2) {
        left: unset;
        right: px2rem(82px);
        transform: rotateY(180deg);
      }
    }
  }
}

.mirror .pg-national .cmp-rule {
  right: unset;
  left: 0;

  &__bg {
    transform: scaleX(-1);
  }

  &__dialog {
    &-list {
      text-align: right;
    }

    &-close {
      right: unset;
      left: px2rem(15px);
    }
  }

  span {
    left: unset;
    right: px2rem(6px);
  }


}