@import '~@/common.scss';

.pg-recharge_send_medal .cmp-medal {
    display: flex;
    flex-direction: column;
    position: relative;

    &__medal {
        width: px2rem(113px);
        height: px2rem(148px);
        background: url(~@RechargeSendMedal/images/medal-bg.webp) no-repeat center/cover;
        display: flex;
        justify-content: center;

        &>img {
            width: px2rem(80px);
            height: px2rem(80px);
            margin-top: px2rem(28px);
        }
    }

    &__text {
        font-size: px2rem(11px);
        font-weight: 500;
        color: #FFED8C;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        bottom: px2rem(13px);
    }

}