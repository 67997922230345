@import '@/common.scss';

.pg-luckygift .rank {
    margin-top: px2rem(-28px);
    width: 100%;
    height: px2rem(894px);
    background-image: url(~@LuckyGift/images/rank-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    padding: 0 px2rem(30px);
    box-sizing: border-box;
    padding-top: px2rem(130px);
    font-size: px2rem(12px);
    position: relative;

    .cmp-empty {
        span {
            margin-top: px2rem(8px);
        }
    }

    .title {
        width: px2rem(54px);
        height: px2rem(13px);
        object-fit: cover;
        position: absolute;
        left: 50%;
        top: px2rem(86px);
        transform: translateX(-50%);
    }

    .list {
        display: flex;
        flex-direction: column;
        // overflow-y: scroll;
        height: px2rem(528px);
        padding-bottom: px2rem(90px);
        box-sizing: border-box;

        .item {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            width: px2rem(314px);
            height: px2rem(84px);
            background-image: url(~@LuckyGift/images/rank-list-bg.webp);
            background-repeat: no-repeat;
            background-size: contain;
            padding-left: px2rem(56px);
            margin-bottom: px2rem(4px);
            color: #FF9E8A;
            box-sizing: border-box;
            position: relative;

            .rank-num {
                position: absolute;
                font-size: px2rem(16px);
                font-weight: 600;
                color: #FF9E8A;
                left: px2rem(20px);
                top: 50%;
                transform: translateY(-50%);
            }

            .avatar {
                flex-shrink: 0;
                width: px2rem(62px);
                height: px2rem(62px);
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid #FF8080;
            }

            .info {
                margin: 0 px2rem(10px);

                .name {
                    font-size: px2rem(14px);
                    font-weight: 600;
                    display: block;
                    max-width: px2rem(180px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .coins {
                    font-weight: 600;
                    display: flex;

                    .gold {
                        display: flex;
                        align-items: center;
                        margin: 0 px2rem(2px);

                        img {
                            width: px2rem(15px);
                            height: px2rem(15px);
                            object-fit: cover;
                        }

                        span {
                            font-size: px2rem(12px);
                            font-weight: bold;
                            margin: 0 px2rem(2px);
                        }
                    }
                }
            }

            &:nth-child(1) {
                background-image: url(~@LuckyGift/images/rank-top1-bg.webp);

                .avatar {
                    border: 2px solid #FFE400;
                }

                .info {
                    color: #7A1500;
                }

                .rank-num {
                    width: px2rem(34px);
                    height: px2rem(33px);
                    background-image: url(~@LuckyGift/images/rank-top1-icon.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    left: px2rem(8px);
                }
            }

            &:nth-child(2) {
                background-image: url(~@LuckyGift/images/rank-top2-bg.webp);

                .avatar {
                    border: 2px solid #FFE400;
                }

                .info {
                    color: #7A1500;
                }

                .rank-num {
                    width: px2rem(27px);
                    height: px2rem(24px);
                    background-image: url(~@LuckyGift/images/rank-top2-icon.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    left: px2rem(10px);
                }
            }

            &:nth-child(3) {
                background-image: url(~@LuckyGift/images/rank-top3-bg.webp);

                .avatar {
                    border: 2px solid #FFE400;
                }

                .info {
                    color: #7A1500;
                }

                .rank-num {
                    width: px2rem(27px);
                    height: px2rem(24px);
                    background-image: url(~@LuckyGift/images/rank-top3-icon.webp);
                    background-repeat: no-repeat;
                    background-size: cover;
                    left: px2rem(10px);
                }
            }


        }

        .item.user {
            background-image: url(~@LuckyGift/images/rank-user-bg.webp);

            .rank-num {
                background-image: unset;
                left: px2rem(20px);
            }

            .rank-num1 {
                width: px2rem(34px);
                height: px2rem(33px);
                background-image: url(~@LuckyGift/images/rank-top1-icon.webp);
                background-repeat: no-repeat;
                background-size: cover;
                left: px2rem(8px);
            }

            .rank-num2 {
                width: px2rem(27px);
                height: px2rem(24px);
                background-image: url(~@LuckyGift/images/rank-top2-icon.webp);
                background-repeat: no-repeat;
                background-size: cover;
                left: px2rem(10px);
            }

            .rank-num3 {
                width: px2rem(27px);
                height: px2rem(24px);
                background-image: url(~@LuckyGift/images/rank-top3-icon.webp);
                background-repeat: no-repeat;
                background-size: cover;
                left: px2rem(10px);
            }
        }



    }
}

.mirror .pg-luckygift .rank {
    .list {

        .item {
            transform: scaleX(-1);
            // padding-right: px2rem(56px);
            // padding-left: unset;
            direction: ltr;

            .rank-num {
                transform: translateY(-50%) scaleX(-1);
                // right: px2rem(20px);
                // left: unset;
            }

            .info {
                transform: scaleX(-1);
                direction: rtl;
            }


        }
    }
}