@import '~@/common.scss';

.cmp-top3 {
  width: px2rem(163px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &.top1 {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  &.top2 {
    position: absolute;
    top: px2rem(115px);
    left: 0;
    z-index: 1;
  }
  &.top3 {
    position: absolute;
    top: px2rem(115px);
    right: 0;
    z-index: 1;
  }
  &__cover {
    position: absolute;
    width: px2rem(163px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &__avatar {
    width: px2rem(82px);
    height: px2rem(82px);
    border-radius: 50%;
    object-fit: cover;
    margin-top: px2rem(19px);
  }
  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: px2rem(21px);
    margin-bottom: px2rem(20px);
    background: transparent;
    justify-content: center;
    span {
      width: px2rem(70px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      font-size: px2rem(12px);
      font-weight: 700;
      color: #fff;
    }
    &-coin {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: px2rem(4px);
      direction: ltr;
      span {
        width: unset;
        color: #fff;
        font-size: px2rem(12px);
      }
      img {
        width: px2rem(14px);
        margin-right: px2rem(6px);
      }
    }
  }
}
