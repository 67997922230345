@import '@/common.scss';

.pg-luckygift .cmp-rule {
  position: absolute;
  top: px2rem(253px);
  right: 0;
  width: px2rem(67px);
  height: px2rem(30px);
  text-align: center;
  line-height: px2rem(30px);
  font-size: px2rem(12px);
  color: #f0ff00;
  display: flex;
  justify-content: center;
  align-items: center;

  &__bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  span {
    position: absolute;
    right: px2rem(16px);
    z-index: 1;
    font-size: px2rem(12px);
    color: #f0ff00;
    font-weight: bold;
  }

  &__dialog {

    &-title {
      width: px2rem(52px);
      height: px2rem(13px);
      position: absolute;
      left: 50%;
      top: px2rem(86px);
      transform: translateX(-50%);
      color: #fff;
    }

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: px2rem(405px);
      background-image: url(~@LuckyGift/images/lucky-chest-bg.webp);
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
    }

    &-list {
      display: inline-block;
      margin: px2rem(120px) px2rem(36px) 0;
      text-align: left;
      font-size: px2rem(14px);
      color: #fff;
      height: px2rem(260px);
      overflow-y: scroll;
    }

    &-item:not(:last-child) {
      margin-bottom: px2rem(15px);
    }

  }
}

.mirror .pg-luckygift .cmp-rule {
  right: unset;
  left: 0;

  &__bg {
    transform: scaleX(-1);
  }

  &__dialog {
    &-list {
      text-align: right;
      direction: rtl;
    }

  }

  span {
    right: unset;
    left: px2rem(16px);
  }


}