@import '~@/common.scss';

.pg-ramadan-answer .cmp-rank {
    margin: 0 px2rem(8px);
    height: px2rem(878px);
    background-image: url(~@RamadanAnswer/images/rank-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    box-sizing: border-box;
    padding: px2rem(87px) px2rem(20px) 0;

    .cmp-empty {
        img {
            width: px2rem(216px);
            height: px2rem(135px);
            margin-bottom: px2rem(4px);
        }

        span {
            font-weight: 600;
            font-size: px2rem(14px);
            color: #FFFFFF;
        }
    }

    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        position: absolute;
        left: 50%;
        top: px2rem(27px);
        transform: translateX(-50%);
        color: rgba(123, 61, 0, 1);
        font-size: px2rem(16px);
        font-weight: 600;
    }

    &__list {
        height: 100%;
        display: flex;
        flex-direction: column;

        &-item,
        &-user {
            height: px2rem(71px);
            display: flex;
            align-items: center;
            position: relative;

            &-index {
                width: px2rem(54px);
                height: 100%;
                color: #7B3D00;
                font-size: px2rem(18px);
                display: flex;
                align-items: center;
                margin-bottom: px2rem(10px);

                &>div {
                    min-width: px2rem(38px);
                    color: #7B3D00;
                    font-size: px2rem(18px);
                    text-align: center;
                    font-weight: 600;
                }
            }

            &-info {
                flex-grow: 1;
                border-bottom: px2rem(1px) solid rgba(123, 61, 0, 0.2);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: px2rem(10px);
                box-sizing: border-box;



                &>.left,
                &>.right {
                    display: flex;
                    align-items: center;
                }
            }

            &-avatar {
                width: px2rem(50px);
                height: px2rem(50px);
                object-fit: cover;
                border-radius: 50%;
                margin-right: px2rem(15px);
            }

            &-nick,
            &-num {
                font-weight: bold;
                font-size: px2rem(14px);
                color: #7B3D00;
            }

            &-nick {
                max-width: px2rem(160px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }


            &-icon {
                width: px2rem(22px);
                height: px2rem(22px);
                object-fit: cover;
                margin: 0 px2rem(4px);
            }

            &:nth-last-child(2) {
                .cmp-rank__list-item-info {
                    border-bottom: unset;
                }
            }

            .rank1 {
                width: px2rem(45px);
                height: px2rem(35px);
                background-image: url(~@RamadanAnswer/images/rank1.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

            .rank2 {
                width: px2rem(43px);
                height: px2rem(33px);
                background-image: url(~@RamadanAnswer/images/rank2.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

            .rank3 {
                width: px2rem(38px);
                height: px2rem(33px);
                background-image: url(~@RamadanAnswer/images/rank3.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

        }

        &-user {
            margin-top: auto;
            margin-bottom: px2rem(12px);

            &-info {
                border-bottom: unset;
            }

            &-index {
                &>div {
                    color: #fff;
                }
            }

            &-num,
            &-nick {
                color: #fff;
            }
        }
    }
}

.mirror .pg-ramadan-answer .cmp-rank {
    &__list {
        &-item {
            &-avatar {
                margin-right: unset;
                margin-left: px2rem(15px);
            }
        }
    }
}