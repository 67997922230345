@import '~@/common.scss';
@import './var.scss';

.#{$prefix-cls} {
    position: relative;
    min-height: 100vh;
    background: #0C0538;
    padding-bottom: px2rem(50px);

    &__header {
        position: relative;
        margin-bottom: px2rem(20px);

        &>img {
            width: 100%;
            height: px2rem(496px);
            object-fit: cover;
            margin-bottom: px2rem(-88px);
        }

        &-phase {
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: px2rem(340px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(47, 28, 0, 0.79) 53%, rgba(0, 0, 0, 0) 100%);
            padding-bottom: px2rem(200px);
            padding-top: px2rem(10px);

            span {
                font-weight: 600;
                font-size: px2rem(12px);
                color: #FFFFFF;
                margin-bottom: px2rem(6px);

                &:first-child {
                    font-weight: bold;
                    font-size: px2rem(17px);
                    color: #FFE800;
                    margin-bottom: px2rem(4px);
                }
            }
        }

        &-agency {
            position: absolute;
            top: px2rem(50px);
            left: 50%;
            transform: translateX(-50%);

            &-img {
                width: px2rem(358px);
                height: px2rem(208px);
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-avatar {
                width: px2rem(65px);
                height: px2rem(65px);
                border-radius: 50%;
                object-fit: cover;
                margin-top: px2rem(15px);
            }

            &-frame {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 2;
            }

            &-name {
                font-weight: 600;
                font-size: px2rem(17px);
                color: #FFE100;
                text-align: center;
                line-height: px2rem(30px);
                margin: px2rem(-30px) auto 0;
                width: px2rem(260px);
                height: px2rem(30px);
                background: url('./images/countdown-bg.webp') no-repeat center/contain;
            }
        }
    }

    .cmp-bottom-statement {
        font-weight: 400;
        color: rgba($color: #FFFFFF, $alpha: 0.6);
    }

    .cmp-tab-buttons {
        margin-top: px2rem(5px);

        &-btn {
            padding: unset;

            &__item {
                opacity: unset;
                background-image: url('./images/tab-unac.webp');
                background-size: contain;
                height: px2rem(41px);
                font-size: px2rem(14px);
                font-weight: bold;
                margin: unset;
                color: rgba(248, 227, 140, 0.6);
                background-size: cover;
            }

            &__text {
                font-size: px2rem(13px);
            }

            .selected {
                background-image: url('./images/tab-ac.webp');

                &>span {
                    color: rgba(248, 227, 140, 1);
                }
            }
        }

    }

    &__container {
        margin: px2rem(21px) px2rem(10px) 0;
        height: px2rem(666px);
        background: url('./images/list-bg.webp') no-repeat center/cover;
        position: relative;
        padding-top: px2rem(66px);
        box-sizing: border-box;

        &-title {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: px2rem(20px);
            color: rgba(255, 232, 0, 1);
            font-size: px2rem(16px);
            font-style: italic;
            text-shadow: 0 px2rem(2px) px2rem(2px) rgba(66, 20, 10, 1);
            font-weight: bold;
        }
    }
}

.mirror .#{$prefix-cls} {
    header>div {
        direction: ltr;
    }
}