@import '~@/common.scss';

.pg-recharge_send_medal .cmp-get-dialog {
    .cmp-dialog {
        &__content {
            height: px2rem(261px);
            background-image: url(~@RechargeSendMedal/images/get-dialog-bg.webp);
            display: flex;
            flex-direction: column;

            .medal-box {
                display: flex;
                margin-top: px2rem(30px);
                width: 100%;
                justify-content: space-between;
                padding: 0 px2rem(46px);
                box-sizing: border-box;
            }

            .get {
                width: px2rem(99px);
                height: px2rem(50px);
                object-fit: cover;
            }
        }
    }
}