@import '@/common.scss';

.pg-luckygift .chest {
    // margin-top: px2rem(-86px);
    width: 100%;
    height: px2rem(405px);
    background-image: url(~@LuckyGift/images/lucky-chest-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: px2rem(122px);
    font-size: px2rem(12px);
    box-sizing: border-box;

    .title {
        width: px2rem(102px);
        height: px2rem(15px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: px2rem(86px);
    }



    .header {
        display: flex;
        justify-content: space-between;
        padding: 0 px2rem(30px);

        .left,
        .right {
            display: flex;
            align-items: center;

            span {
                font-size: px2rem(11px);
                font-weight: bold;
                color: #E4C330;
                margin-right: px2rem(6px);
            }
        }

        .gold {
            display: flex;
            align-items: center;

            img {
                width: px2rem(11px);
                height: px2rem(11px);
                object-fit: cover;
            }

            span {
                font-size: px2rem(12px);
                font-weight: bold;
                color: #FFFFFF;
                margin: 0 px2rem(2px);
                -webkit-text-stroke: 1px transparent;
                background-color: #631515;
                -webkit-background-clip: text;
            }
        }

        .right {
            .avatar {
                display: flex;
                align-items: center;

                span {
                    font-weight: bold;
                    color: #FFFFFF;
                    -webkit-text-stroke: 1px transparent;
                    background-color: #631515;
                    -webkit-background-clip: text;
                }

                .avatar-list {
                    img {
                        width: px2rem(17px);
                        height: px2rem(17px);
                        border-radius: 50%;
                        object-fit: cover;
                        border: px2rem(1px) solid #FFDF2A;
                        box-sizing: border-box;

                        &:nth-child(1) {
                            margin-left: px2rem(1px);
                        }

                        &:nth-child(2) {
                            margin-left: px2rem(-5px);
                        }

                        &:nth-child(3) {
                            margin-left: px2rem(-5px);
                        }
                    }
                }

            }
        }
    }

    .content {
        position: relative;

        .top-three {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .item {
                width: px2rem(172px);
                height: px2rem(106px);
                background-repeat: no-repeat;
                background-size: contain;
                position: relative;
                flex-shrink: 0;
                margin: px2rem(-28px) px2rem(5px) 0;

                &:nth-child(1) {
                    margin-left: px2rem(30px);
                    margin-right: px2rem(30px);
                    background-image: url(~@LuckyGift/images/lucky-chest-top1.webp);
                }

                &:nth-child(2) {
                    background-image: url(~@LuckyGift/images/lucky-chest-top2.webp);
                    margin-top: px2rem(-60px);
                }

                &:nth-child(3) {
                    background-image: url(~@LuckyGift/images/lucky-chest-top3.webp);
                    margin-top: px2rem(-60px);
                }

                &:nth-child(4) {
                    background-image: url(~@LuckyGift/images/rewards-top-4.webp);
                    margin-top: px2rem(-45px);
                }

                &:nth-child(5) {
                    background-image: url(~@LuckyGift/images/rewards-top-5.webp);
                    margin-top: px2rem(-45px);
                }

                &:nth-child(6) {
                    background-image: url(~@LuckyGift/images/rewards-top-6.webp);
                    margin-top: px2rem(-45px);
                }

                &:nth-child(7) {
                    background-image: url(~@LuckyGift/images/rewards-top-7.webp);
                    margin-top: px2rem(-45px);
                }

                .gold {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: px2rem(60px);
                    left: 50%;
                    transform: translateX(-50%);

                    img {
                        width: px2rem(15px);
                        height: px2rem(15px);
                        object-fit: cover;
                    }

                    span {
                        font-size: px2rem(12px);
                        font-weight: bold;
                        color: #FFFFFF;
                        margin: 0 px2rem(2px);
                        text-shadow: #631515 px2rem(1px) 0 0, #631515 0 px2rem(1px) 0, #631515 px2rem(-1px) 0 0, #631515 0 px2rem(-1px) 0;
                    }
                }
            }
        }

        .chest-box {
            .gold-box {
                position: absolute;
                width: px2rem(181px);
                height: px2rem(173px);
                left: 50%;
                transform: translateX(-50%);
                top: px2rem(40px);
                object-fit: cover;
            }

            .btn-get {
                position: absolute;
                width: px2rem(124px);
                height: px2rem(34px);
                left: 50%;
                transform: translateX(-50%);
                top: px2rem(203px);

                .bg {
                    position: absolute;
                    width: px2rem(124px);
                    height: px2rem(34px);
                    object-fit: cover;
                    top: 0;
                    left: 0;
                }

                .get {
                    width: px2rem(21px);
                    height: px2rem(11px);
                    object-fit: cover;
                    position: absolute;
                    top: px2rem(15px);
                    left: 50%;
                    transform: translateX(-50%);
                }

                .done {
                    width: px2rem(33px);
                    height: px2rem(11px);
                }
            }
        }

        .rewards-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            padding: 0 px2rem(11px);
            margin-top: px2rem(-36px);
            box-sizing: border-box;

            .item {
                width: px2rem(172px);
                height: px2rem(102px);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-repeat: no-repeat;
                background-size: cover;
                flex-shrink: 0;

                .gold {
                    display: flex;
                    align-items: center;
                    margin-top: px2rem(34px);

                    img {
                        width: px2rem(15px);
                        height: px2rem(15px);
                        object-fit: cover;
                    }

                    span {
                        margin: 0 px2rem(2px);
                        font-size: px2rem(12px);
                        font-weight: bold;
                        color: #FFFFFF;
                        text-shadow: #631515 px2rem(1px) 0 0, #631515 0 px2rem(1px) 0, #631515 px2rem(-1px) 0 0, #631515 0 px2rem(-1px) 0;
                    }
                }
            }

            .item:nth-child(1) {
                background-image: url(~@LuckyGift/images/rewards-top-4.webp);
            }

            .item:nth-child(2) {
                background-image: url(~@LuckyGift/images/rewards-top-5.webp);
            }

            .item:nth-child(3) {
                margin-top: px2rem(-30px);
                background-image: url(~@LuckyGift/images/rewards-top-6.webp);
            }

            .item:nth-child(4) {
                margin-top: px2rem(-30px);
                background-image: url(~@LuckyGift/images/rewards-top-7.webp);
            }
        }

    }

    .spin-loading {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100vw;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



.mirror .pg-luckygift .chest {
    .header {

        .left,
        .right {

            span {
                margin-right: unset;
                margin-left: px2rem(6px);
            }
        }

        .right {
            .avatar {
                .avatar-list {
                    img {
                        margin-left: unset;

                        &:nth-child(1) {
                            margin-right: px2rem(1px);
                        }

                        &:nth-child(2) {
                            margin-right: px2rem(-5px);
                        }

                        &:nth-child(3) {
                            margin-right: px2rem(-5px);
                        }
                    }
                }
            }
        }

        .gold {
            span {
                margin: 0 px2rem(2px);
            }
        }
    }
}