@import '~@/common.scss';

.pg-charm-anchor .cmp-rank-list {
    &-top1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__avatar {
            display: flex;
            align-items: flex-end;
        }

        &__self {
            width: px2rem(175px);
            height: px2rem(115px);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            position: relative;
            margin: 0 px2rem(-20px);

            &::after {
                content: '';
                position: absolute;
                width: px2rem(48px);
                height: px2rem(35px);
                background: url(~@CharmAnchor/images/love.webp) no-repeat center/cover;
                bottom: px2rem(4px);
                right: px2rem(17px);
                z-index: 3;
            }

            .profile {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }

            .avatar {
                width: px2rem(80px);
                height: px2rem(80px);
                object-fit: cover;
                border-radius: 50%;
                margin-top: px2rem(5px);
            }
        }

        &__other {
            width: px2rem(76px);
            height: px2rem(80px);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 0 px2rem(-20px);

            .profile {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }

            .avatar {
                width: px2rem(56px);
                height: px2rem(56px);
                object-fit: cover;
                border-radius: 50%;
                margin-top: px2rem(3px);
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__nick {
            font-size: px2rem(14px);
            font-weight: 500;
            color: #FCE548;
            margin-bottom: px2rem(4px);
            margin-top: px2rem(5px);
        }

        &__gold {
            display: flex;
            align-items: center;

            &>span {
                font-size: px2rem(12px);
                font-weight: bold;
                color: #F1E8B2;
            }

            &>img {
                width: px2rem(11px);
                height: px2rem(11px);
                object-fit: cover;
                margin: 0 px2rem(4px);
            }
        }
    }

    &-content {
        margin-top: px2rem(11px);
    }

    &-item {
        display: flex;
        justify-content: space-between;
        padding: px2rem(10px);
        margin-bottom: px2rem(5px);

        &:nth-child(2n-1) {
            background-color: rgba(104, 5, 5, 0.4);
        }

        &:nth-child(2n) {
            background-color: rgba(104, 42, 5, 0.6);
        }

        &>.left {
            display: flex;
            align-items: center;

            &>span:first-child {
                font-size: px2rem(16px);
                font-weight: bold;
                color: #FFFFFF;
            }

            &>div {
                display: flex;
                align-items: flex-end;
                margin: 0 px2rem(11px);

                &>img:first-child {
                    width: px2rem(40px);
                    height: px2rem(40px);
                    border: px2rem(1px) solid #FFC223;
                    border-radius: 50%;
                    object-fit: cover;
                }

                &>img:last-child {
                    width: px2rem(22px);
                    height: px2rem(22px);
                    border: px2rem(1px) solid #FFC223;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: px2rem(-10px);
                }
            }

            &>span:last-child {
                font-size: px2rem(14px);
                color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: px2rem(160px);
            }
        }

        &>.right {
            display: flex;
            align-items: center;

            img {
                width: px2rem(11px);
                height: px2rem(11px);
                object-fit: cover;
                margin: 0 px2rem(4px);
            }

            span {
                font-size: px2rem(14px);
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
}

.mirror .pg-charm-anchor .cmp-rank-list {
    &-top1 {
        direction: ltr;
    }
}