@import '~@/common.scss';

.pg-charm-anchor .cmp-rule {
    position: absolute;
    width: px2rem(68px);
    height: px2rem(37px);
    background: url(~@CharmAnchor/images/rule-btn.webp) no-repeat center/cover;
    top: px2rem(281px);
    right: 0;

    .cmp-dialog {
        &__content {
            width: 100%;
            background-image: url(~@CharmAnchor/images/rule-bg.webp);
            padding: px2rem(57px) px2rem(30px) 0;
            box-sizing: border-box;

            .text {
                height: px2rem(300px);
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                overflow-y: auto;
                box-sizing: border-box;

                span {
                    margin-bottom: px2rem(20px);
                    font-size: px2rem(14px);
                    font-weight: bold;
                    color: #FFFFFF;

                    &:last-child {
                        margin-bottom: unset;
                    }
                }
            }
        }

        &__title {
            top: px2rem(41px);
            font-size: px2rem(14px);
            font-weight: bold;
            color: #FCE548;
        }

        &__close {
            top: px2rem(7px);
            right: px2rem(8px);
            left: unset;
        }
    }

}

.mirror .pg-charm-anchor .cmp-rule {
    .cmp-dialog {
        &__content {
            .text {
                direction: rtl;
            }
        }
    }
}