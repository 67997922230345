@import '@/common.scss';

.pg-invite {
  width: px2rem(375px);
  position: relative;
  background-color: #132e10;
  overflow-x: hidden;
  & .header-banner {
    width: 100%;
    height: px2rem(375px);
  }

  & .bottom__tip {
    text-align: center;
    width: px2rem(273px);
    font-size: px2rem(12px);
    color: #9fa89c;
    padding: px2rem(24px) px2rem(51px) px2rem(35px) px2rem(51px);
  }

  .confirm-btn,
  .cancel-btn {
    width: px2rem(200px);
    height: px2rem(35px);
    line-height: px2rem(35px);
    border-radius: px2rem(18px);
    background-color: rgb(57, 141, 21);
    border: px2rem(2px) solid rgb(248, 219, 0);
    font-size: px2rem(14px);
    box-sizing: border-box;
    font-weight: 600;
    color: #f0ff00;
    text-align: center;
    margin: auto;
    margin-top: px2rem(30px);
  }
  .cancel-btn {
    width: px2rem(147px);
    margin: 0 px2rem(13px);
    color: #dbdfbf;
    background-color: rgb(121, 148, 107);
    border: none;
  }
}

.mirror .pg-invite {
  direction: rtl;
}
