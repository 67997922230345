@import '~@/common.scss';
@import '../../../var.scss';

.#{$prefix-cls} .cmp-game-task {
  width: px2rem(369px);
  height: px2rem(143px);
  background-image: url('./images/item.webp');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0 px2rem(24px);
  box-sizing: border-box;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: px2rem(16px);

    &-box {
      width: px2rem(66px);
      height: px2rem(66px);
      background-image: url('./images/gift-box.webp');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: px2rem(50px);
        height: px2rem(50px);
        object-fit: fill;
      }
    }

    &-count {
      color: rgba(255, 220, 0, 1);
      font-size: px2rem(14px);
      margin-top: px2rem(2px);
    }
  }

  &__middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: px2rem(90px);

    &-title {
      font-size: px2rem(13px);
      color: rgba(239, 212, 146, 1);
    }

    &-description {
      width: 100%;
      margin-top: px2rem(6px);
      font-size: px2rem(13px);
      color: rgba(192, 192, 192, 1);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      /* 设置显示3行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__right {
    margin-left: px2rem(11px);

    img {
      width: px2rem(91px);
      height: px2rem(30px);
      object-fit: fill;
    }
  }
}

.mirror .#{$prefix-cls} .cmp-game-task {
  &__left {
    margin-left: px2rem(16px);
    margin-right: unset;
  }

  &__right {
    margin-left: unset;
    margin-right: px2rem(11px);
  }
}