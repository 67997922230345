@import '@/common.scss';

.pg-national {
  width: px2rem(375px);
  position: relative;
  background-color: #1E0E1D;
  overflow-x: hidden;

  & .header-banner {
    width: 100%;
    object-fit: cover;
  }

  & .bottom__tip {
    text-align: center;
    font-size: px2rem(12px);
    color: #999999;
    padding: px2rem(17px) px2rem(57px) px2rem(23px);
  }


}

.mirror .pg-national {
  direction: rtl;
}