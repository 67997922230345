@import '~@/common.scss';

.pg-recharge_send_medal .cmp-rule {
    position: absolute;
    width: px2rem(33px);
    height: px2rem(33px);
    background: url(~@RechargeSendMedal/images/rule-btn.webp) no-repeat center/cover;
    top: px2rem(13px);
    right: px2rem(13px);

    .cmp-dialog {
        &__content {
            .text {
                margin-top: px2rem(30px);
                height: px2rem(344px);
                display: flex;
                flex-direction: column;
                padding: 0 px2rem(15px);
                box-sizing: border-box;
                overflow-y: auto;

                span {
                    margin-bottom: px2rem(20px);
                    font-size: px2rem(12px);
                    font-weight: bold;
                    color: #FFED8C;
                }
            }
        }
    }
}

.mirror .pg-recharge_send_medal .cmp-rule {
    right: unset;
    left: px2rem(13px);
}