@import '@/common.scss';

.pg-invite {
  .cmp-rewards {
    display: flex;
    align-items: center;
    flex-direction: column;
    &__content {
      height: px2rem(594px);
    }
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        width: px2rem(220px);
        height: px2rem(25px);
        margin-top: px2rem(19px);
      }
      &-list {
        display: flex;
      }
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: px2rem(19px) px2rem(13px) 0 px2rem(13px);
        .bg {
          width: px2rem(87px);
          height: px2rem(90px);
          background-image: url(~@Invite/images/dialog-reward-item-bg.webp);
          background-repeat: no-repeat;
          background-size: contain;
          img {
            width: px2rem(57px);
            height: px2rem(57px);
            margin: px2rem(17px) px2rem(15px);
          }
        }
        span {
          display: block;
          font-size: px2rem(11px);
          font-weight: 600;
          color: #d7ecbb;
          margin-top: px2rem(10px);
          margin-bottom: px2rem(11px);
        }
      }
    }
  }
}
