@import '~@/common.scss';

.pg-room-support .cmp-rule {
    padding: 0 px2rem(25px);
    font-weight: 500;
    font-size: px2rem(14px);
    color: #FFFFFF;
    display: flex;
    flex-direction: column;

    span {
        margin-bottom: px2rem(20px);
    }
}