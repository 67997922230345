@import '@/common.scss';

.pg-invite .invite-reward {
  width: 100%;
  padding: 0 px2rem(5px);
  margin-top: px2rem(-14px);

  &-body {
    width: px2rem(365px);
    height: px2rem(245px);
    box-sizing: border-box;
    background-image: url(~@Invite/images/invite-reward-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    display: flex;
    padding: 0 px2rem(23px);

    .title {
      font-size: px2rem(15px);
      font-weight: bold;
      color: #f0ff00;
      position: absolute;
      top: px2rem(9px);
      left: px2rem(100px);
      width: px2rem(163px);
      text-align: center;
    }

    .level-text {
      font-size: px2rem(14px);
      width: 100%;
      font-weight: 600;
      color: #71b17d;
      position: absolute;
      top: px2rem(52px);
      left: px2rem(33px);

      span:nth-child(1) {
        margin-right: px2rem(120px);
      }
    }

    .reward-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: px2rem(84px) px2rem(3px) 0 px2rem(3px);
      position: relative;

      img {
        width: px2rem(90px);
        height: px2rem(90px);
      }

      span {
        font-weight: 600;
        color: #d7ecbb;
        font-size: px2rem(11px);
        margin-top: px2rem(10px);
        display: block;
        white-space: nowrap;
        width: px2rem(90px);
      }

      &:nth-child(3) {
        margin-right: px2rem(83px);
      }
    }

    .first-percent {
      width: px2rem(185px);
      height: px2rem(24px);
      font-size: px2rem(10px);
      font-weight: 600;
      color: #f0ff00;
      line-height: px2rem(12px);
      text-align: center;
      position: absolute;
      right: px2rem(25px);
      top: px2rem(201px);
    }
  }

  z-index: 1;

  &__dialog {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 1000;

    &-body {
      width: px2rem(356px);
      height: px2rem(584px);
      background-image: url(~@Invite/images/reward-detail-bg.webp);
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;

      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: px2rem(13px);

        .left__line,
        .right__line {
          width: px2rem(66px);
          height: px2rem(8px);
        }

        .left__line {
          transform: rotateY(180deg);
        }

        span {
          font-size: px2rem(14px);
          font-weight: 600;
          color: #f0ff00;
          margin: 0 px2rem(8px);
        }
      }

      .tip {
        font-size: px2rem(11px);
        font-weight: 600;
        color: #f0ff00;
        width: 100%;
        text-align: center;
        margin-top: px2rem(29px);
      }

      .reward-list {
        display: grid;
        grid-template: repeat(3, 3fr) / repeat(2, 2fr);

        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: px2rem(15px);

          .bg {
            width: px2rem(97px);
            height: px2rem(100px);
            background-image: url(~@Invite/images/dialog-reward-item-bg.webp);
            background-repeat: no-repeat;
            background-size: contain;

            img {
              width: px2rem(63px);
              height: px2rem(63px);
              margin: px2rem(19px) px2rem(17px);
            }
          }

          span {
            display: block;
            font-size: px2rem(11px);
            font-weight: 600;
            color: #d7ecbb;
            margin-top: px2rem(10px);
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.mirror .pg-invite .invite-reward {
  &-body {
    .title {
      left: 0;
      right: px2rem(100px);
    }

    .level-text {
      left: unset;
      right: px2rem(33px);
      display: flex;

      span:nth-child(1) {
        margin-right: unset;
        margin-left: px2rem(120px);
      }
    }

    .reward-item {
      &:nth-child(3) {
        margin-right: unset;
        margin-left: px2rem(93px);
      }
    }

    .first-percent {
      right: unset;
      width: px2rem(120px);
      left: px2rem(45px);
    }
  }

  &__dialog {
    &-body {
      .header {
        direction: ltr;
      }
    }
  }
}