@import '@/common.scss';

.pg-national .rank {
    width: 100%;
    height: px2rem(1120px);
    background-image: url(~@National/images/rank-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 px2rem(5px);
    box-sizing: border-box;

    .spin-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .user-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: px2rem(14px) px2rem(16px);
        font-size: px2rem(14px);
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: px2rem(5px);
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-radius: 2px;

        .left {
            display: flex;
            align-items: center;

            .index {
                margin-right: px2rem(16px);
            }

            img {
                width: px2rem(50px);
                height: px2rem(50px);
                border-radius: 50%;
                margin-right: px2rem(11px);
                object-fit: cover;
            }

            .user-info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                span:first-child {
                    margin-bottom: px2rem(6px);
                    width: px2rem(175px);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                span:last-child {
                    font-size: px2rem(12px);
                    color: #999999;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;

            img {
                width: px2rem(10px);
                height: px2rem(10px);
                margin-right: px2rem(3px);
            }

            span {
                font-size: px2rem(12px);
                font-weight: 600;
                color: #FFDD00;
            }
        }
    }

    .top-three {
        position: relative;
        padding-top: px2rem(56px);

        .item {
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            flex-direction: column;
            align-items: center;

            .avatar {
                margin-top: px2rem(51px);
                width: px2rem(55px);
                height: px2rem(55px);
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
                object-fit: cover;
            }

            .name {
                font-size: px2rem(12px);
                font-weight: 600;
                color: #FFFFFF;
                margin-top: px2rem(11px);
            }

            .coin {
                display: flex;
                align-items: center;
                margin-top: px2rem(27px);

                img {
                    width: px2rem(10px);
                    height: px2rem(10px);
                    margin-right: px2rem(3px);
                }

                span {
                    color: #FFDD00;
                    font-size: px2rem(12px);
                }
            }

            &:nth-child(1) {
                width: px2rem(147px);
                height: px2rem(158px);
                background-image: url(~@National/images/top1.webp);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &:nth-child(2) {
                width: px2rem(139px);
                height: px2rem(152px);
                background-image: url(~@National/images/top2.webp);
                position: absolute;
                top: px2rem(183px);
                left: 0;
            }

            &:nth-child(3) {
                width: px2rem(139px);
                height: px2rem(147px);
                background-image: url(~@National/images/top3.webp);
                position: absolute;
                top: px2rem(188px);
                right: 0;

                .avatar {
                    margin-top: px2rem(48px);
                }

                .name {
                    margin-top: px2rem(6px);
                }
            }
        }
    }

    .top3-tip {
        padding: px2rem(5px) 0;
        font-size: px2rem(12px);
        font-weight: bold;
        color: #A37AFF;
        text-align: center;
        margin-top: px2rem(313px);
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-radius: 2px;
    }

    .list {
        width: 100%;
        height: px2rem(576px);
        overflow-y: auto;
        margin-top: px2rem(10px);
    }

    .user-rank {
        .user-item {
            margin: unset;
            margin-top: px2rem(10px);
            background-color: rgba(182, 0, 214, 0.2);
            border: px2rem(2px) solid rgba(255, 225, 0, 0.52);
        }
    }
}

.mirror .pg-national .rank {
    height: px2rem(1140px);

    .top-three {
        .item {
            &:nth-child(2) {
                right: 0;
            }

            &:nth-child(3) {
                right: unset;
                left: 0;
            }

            .coin {
                img {
                    margin-right: unset;
                    margin-left: px2rem(3px);
                }
            }
        }
    }

    .user-item {

        .left {
            .index {
                margin-right: unset;
                margin-left: px2rem(16px);
            }

            img {
                margin-left: px2rem(11px);
                margin-right: unset;
            }
        }

        .right {

            img {
                margin-right: unset;
                margin-left: px2rem(3px);
            }

        }
    }
}