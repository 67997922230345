@import '@/common.scss';

.pg-invite .my-invitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  .mime {
    width: px2rem(365px);
    height: px2rem(238px);
    background-image: url(~@Invite/images/my-invitation-mime-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;

    .user-info {
      display: flex;
      align-items: center;
      margin-top: px2rem(44px);
      margin-bottom: px2rem(20px);
      img {
        width: px2rem(55px);
        height: px2rem(55px);
        border-radius: 50%;
        border: px2rem(2px) solid rgb(245, 184, 0);
        margin: 0 px2rem(15px) 0 px2rem(35px);
      }
      &-nick {
        p {
          margin: px2rem(4px) 0;
          font-weight: bold;
        }
        p:nth-child(1) {
          font-size: px2rem(14px);
          color: #ffffff;
        }
        p:nth-child(2) {
          font-size: px2rem(12px);
          color: rgb(158, 181, 161);
        }
      }
    }
  }
  &-code {
    width: px2rem(295px);
    height: px2rem(35px);
    line-height: px2rem(35px);
    background: #002905;
    border: px2rem(1px) solid rgb(245, 184, 0);
    border-radius: px2rem(16px);
    margin: auto;
    font-size: px2rem(12px);
    font-weight: 600;
    color: #ffffff;
    padding: 0 px2rem(5px) 0 px2rem(15px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .copy {
      width: px2rem(65px);
      height: px2rem(25px);
      line-height: px2rem(25px);
      text-align: center;
      border-radius: px2rem(15px);
      background-color: rgb(105, 127, 105);
    }
  }
  .invite-friends--btn {
    width: px2rem(200px);
    height: px2rem(35px);
    line-height: px2rem(35px);
    font-size: px2rem(14px);
    font-weight: 600;
    text-align: center;
    color: #f0ff00;
    background-color: rgb(57, 141, 21);
    border: px2rem(2px) solid rgb(248, 219, 0);
    box-sizing: border-box;
    border-radius: px2rem(16px);
    margin: px2rem(25px) px2rem(83px) 0 px2rem(83px);
  }
  .my-data {
    margin-top: px2rem(30px);
    &-header {
      width: px2rem(356px);
      height: px2rem(44px);
      background: url(~@Invite/images/my-data-header-bg.webp);
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: px2rem(13px);
      .left__line,
      .right__line {
        width: px2rem(66px);
        height: px2rem(8px);
      }
      .left__line {
        transform: rotateY(180deg);
      }
      span {
        font-size: px2rem(14px);
        font-weight: 600;
        color: #f0ff00;
        margin: 0 px2rem(8px);
      }
    }
    &-body {
      width: px2rem(354px);
      height: px2rem(397px);
      background-color: #054714;
      .data-list {
        display: grid;
        grid-template: repeat(2, 2fr) / repeat(2, 2fr);
        justify-items: center;
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: px2rem(150px);
          height: px2rem(82px);
          background-color: #002905;
          border-radius: px2rem(10px);
          margin-top: px2rem(20px);
          .title {
            margin-top: px2rem(15px);
          }
          .title,
          span {
            font-size: px2rem(12px);
            font-weight: 600;
            color: #d7ecbb;
            margin-bottom: px2rem(20px);
          }
          span {
            color: #fff;
          }
        }
      }
      .extract-golds-tip {
        font-size: px2rem(14px);
        font-weight: 600;
        color: #f0ff00;
        width: 100%;
        text-align: center;
        margin: px2rem(30px) 0 px2rem(10px) 0;
      }
      .my-income {
        width: px2rem(320px);
        height: px2rem(35px);
        background: #002905;
        border-radius: px2rem(10px);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: px2rem(13px);
          height: px2rem(13px);
          margin: 0 px2rem(6px);
        }
        span {
          font-size: px2rem(14px);
          font-weight: bold;
          color: #fee34c;
          line-height: px2rem(17px);
        }
      }
      .action {
        margin-top: px2rem(30px);
        display: flex;
        justify-content: center;
        &-btn {
          width: px2rem(147px);
          height: px2rem(35px);
          line-height: px2rem(35px);
          font-size: px2rem(14px);
          font-weight: 600;
          text-align: center;
          color: #f0ff00;
          background-color: rgb(57, 141, 21);
          border: px2rem(2px) solid rgb(248, 219, 0);
          box-sizing: border-box;
          border-radius: px2rem(16px);
          margin: 0 px2rem(13px);
        }
      }
    }
  }
}

.mirror .pg-invite .my-invitation {
  .my-invitation-code {
    padding: 0 px2rem(15px) 0 px2rem(5px);
  }
  .user-info {
    img {
      margin: 0 px2rem(35px) 0 px2rem(15px);
    }
  }

  .my-data {
    margin-top: px2rem(30px);
    &-header {
      direction: ltr;
    }
  }
}
