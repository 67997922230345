@import '@/common.scss';

.pg-national .rewards {
    width: 100%;
    height: px2rem(1321px);

    img {
        width: 100%;
        height: 100%;
    }
}