@import '~@/common.scss';
@import '../../var.scss';

.#{$prefix-cls} .cmp-prize {
    width: px2rem(369px);
    height: px2rem(365px);
    background-image: url(../../images/dialog.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: px2rem(75px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.4), transparent);
        display: flex;
        justify-content: center;
        align-items: center;
        width: px2rem(300px);

        span {
            text-align: center;
            font-size: px2rem(26px);
            font-weight: bold;
            background: linear-gradient(to bottom, white, rgba(255, 190, 0, 1));
            -webkit-background-clip: text;
            font-style: italic;
            color: transparent;
        }
    }

    &__bean {
        margin-top: px2rem(13px);
        margin-top: px2rem(35px);
        width: px2rem(80px);
        height: px2rem(80px);
        object-fit: fill;
    }

    &__num {
        margin-top: px2rem(10px);
        font-size: px2rem(16px);
        color: rgba(255, 220, 0, 1);
    }

    &__get {
        margin-top: px2rem(37px);
        width: px2rem(91px);
        height: px2rem(30px);
        object-fit: fill;
    }
}