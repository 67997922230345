@import '@/common.scss';

.pg-invite .cmp-rank {
  width: px2rem(365px);
  height: px2rem(897px);
  background-image: url(~@Invite/images/rank-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  padding: px2rem(55px) px2rem(7px) 0;
  box-sizing: border-box;
  margin: 0 px2rem(5px);
  margin-top: px2rem(-8px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    padding-top: 1px;
    margin-top: px2rem(15px);
    height: px2rem(748px);
    position: relative;

    &-list {
      margin: px2rem(288px) px2rem(2px) 0;
    }
  }

  .cmp-empty {
    position: absolute;
    top: px2rem(50px);
    left: 50%;
    transform: translateX(-50%);
  }

  .adm-tabs {
    &-header {
      border-bottom: none;
    }

    &-tab {
      position: relative;
      font-size: px2rem(12px);
      font-weight: 600;
      color: #80a400;

      &-line {
        background-color: #e2ff00;
        width: px2rem(16px) !important;
        left: px2rem(20px);
      }

      &-active {
        color: #e2ff00;
      }
    }
  }

  &.rewards {
    height: px2rem(727px);
    background-image: url(~@Invite/images/reward-bg.webp);
  }
}

.mirror .pg-invite .cmp-rank {
  .adm-swiper-track-inner {
    .adm-swiper-slide:last-child {
      right: -100%;
    }
  }
}