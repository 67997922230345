@import '~@/common.scss';

.pg-room-support .cmp-rank {
    height: 100%;
    box-sizing: border-box;
    padding: 0 px2rem(24px);
    box-sizing: border-box;

    .cmp-empty {
        img {
            width: px2rem(216px);
            height: px2rem(135px);
            margin-bottom: px2rem(4px);
        }

        span {
            font-weight: 600;
            font-size: px2rem(14px);
            color: #FFFFFF;
        }
    }

    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__list {
        height: 100%;
        display: flex;
        flex-direction: column;
        max-height: px2rem(575px);
        overflow-y: scroll;

        &-item,
        &-user {
            height: px2rem(71px);
            display: flex;
            align-items: center;
            position: relative;
            flex-shrink: 0;

            &-index {
                width: px2rem(54px);
                height: 100%;
                font-weight: bold;
                color: #FFFFFF;
                font-size: px2rem(18px);
                display: flex;
                align-items: center;

                &>div {
                    min-width: px2rem(38px);
                    text-align: center;
                }
            }

            &-info {
                height: 100%;
                flex-grow: 1;
                border-bottom: px2rem(1px) solid rgba(255, 255, 255, 0.2);
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
            }

            &-avatar {
                width: px2rem(50px);
                height: px2rem(50px);
                object-fit: cover;
                border-radius: 50%;
                // margin-right: px2rem(15px);
            }

            &-nick,
            &-num {
                font-weight: 600;
                font-size: px2rem(14px);
                color: #FFFFFF;
            }

            &-nick {
                max-width: px2rem(100px);
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }


            &-icon {
                width: px2rem(20px);
                height: px2rem(20px);
                object-fit: cover;
                margin: 0 px2rem(4px);
            }

            &:last-child {
                .cmp-rank__list-item-info {
                    border-bottom: unset;
                }
            }

            .rank1 {
                width: px2rem(41px);
                height: px2rem(41px);
                background-image: url(~@RoomSupport/images/top-1.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

            .rank2 {
                width: px2rem(38px);
                height: px2rem(34px);
                background-image: url(~@RoomSupport/images/top-2.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

            .rank3 {
                width: px2rem(36px);
                height: px2rem(32px);
                background-image: url(~@RoomSupport/images/top-3.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

        }

        &-user {
            width: 100%;
            height: px2rem(107px);
            margin-top: auto;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 2;
            background-image: url(~@RoomSupport/images/user-bg.webp);
            background-repeat: no-repeat;
            background-size: cover;
            padding: 0 px2rem(25px);
            box-sizing: border-box;

            &-info {
                border-bottom: unset;
                display: flex;
                align-items: center;
            }
        }
    }
}

.mirror .pg-room-support .cmp-rank {
    &__list {
        &-item {
            &-avatar {
                margin-right: unset;
                margin-left: px2rem(15px);
            }
        }
    }
}