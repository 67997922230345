@import '~@/common.scss';

.pg-room-support {
    position: relative;
    min-height: 100vh;
    background: #3D0000;

    header {
        position: relative;

        &>img {
            width: 100%;
            height: px2rem(496px);
            object-fit: cover;
            margin-bottom: px2rem(-88px);
        }

        &>div {
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: px2rem(324px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                font-weight: 600;
                font-size: px2rem(12px);
                color: #FFFFFF;
                margin-bottom: px2rem(6px);

                &:first-child {
                    font-weight: bold;
                    font-size: px2rem(17px);
                    color: #FFE800;
                    margin-bottom: px2rem(4px);
                }
            }
        }
    }

    .cmp-bottom-statement {
        font-weight: 400;
        color: rgba($color: #FFFFFF, $alpha: 0.6);
    }

    .cmp-tab-buttons {
        margin-top: px2rem(5px);

        &-btn {
            padding: 0 px2rem(2px);

            &__item {
                opacity: unset;
                background-image: url(~@RoomSupport/images/tab-unac.webp);
                background-size: contain;
                height: px2rem(41px);
                font-size: px2rem(14px);
                font-weight: bold;
                color: rgba(248, 227, 140, 0.6);
                margin: 0 px2rem(1px);
            }

            &__text {
                margin-bottom: px2rem(4px);
            }

            .selected {
                background-image: url(~@RoomSupport/images/tab-ac.webp);

                &>span {
                    color: rgba(248, 227, 140, 1);
                }
            }
        }

        // &-children {
        //     background-image: url(~@RamadanAnswer/images/rank-bg.webp);
        //     height: px2rem(872px);
        //     background-size: 100% 100%;
        //     margin-top: px2rem(13px);
        //     padding: 0 px2rem(22px);
        //     padding-top: px2rem(80px);
        //     position: relative;
        // }
    }

    &-rank {
        padding-bottom: px2rem(107px);
    }

    &__container {
        margin: px2rem(21px) px2rem(10px) 0;
        height: px2rem(657px);
        background: url(~@RoomSupport/images/list-bg.webp) no-repeat center/cover;
        position: relative;
        padding-top: px2rem(66px);
        box-sizing: border-box;

        &-title {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: px2rem(11px);
            color: rgba(255, 232, 0, 1);
            font-size: px2rem(16px);
            font-style: italic;
            text-shadow: 0 px2rem(2px) px2rem(2px) rgba(66, 20, 10, 1);
            font-weight: bold;
        }
    }
}

.mirror {
    .pg-room-support {
        header>div {
            direction: ltr;
        }
    }
}