@import '@/common.scss';

.pg-luckygift {
  width: px2rem(375px);
  position: relative;
  background-color: #4D0000;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  & .header-banner {
    width: 100%;
    object-fit: cover;
  }

  .cmp-tab-buttons {
    margin-top: px2rem(-68px);

    &-btn {
      padding: 0 px2rem(15px);

      &__item {
        height: px2rem(60px);
        font-size: px2rem(16px);
        font-style: italic;
        background-image: url(~@LuckyGift/images/tab-button-bg.webp);
        margin: 0;

        &:first-child {
          margin-right: px2rem(17px);
        }
      }

      &__text {
        margin-top: px2rem(12px);
        color: rgba(255, 209, 0, 1); //字体颜色
        text-shadow: #8b2e00 px2rem(1px) 0 0, #8b2e00 0 px2rem(1px) 0, #8b2e00 px2rem(-1px) 0 0, #8b2e00 0 px2rem(-1px) 0;
      }

    }

    &-children {
      margin-top: px2rem(-35px);
    }

  }

  .cmp-bottom-statement {
    margin-top: px2rem(-40px);
  }

  .luckygift-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.6);
    pointer-events: none;
    touch-action: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.mirror .pg-luckygift {
  direction: rtl;
}