@import '@/common.scss';

.pg-invite {
  .detail {
    &-dialog {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #000000, $alpha: 0.6);
      .body {
        width: px2rem(356px);
        height: px2rem(584px);
        background: url(~@Invite/images/detail-bg.webp);
        background-repeat: no-repeat;
        background-size: contain;
        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: px2rem(13px);
          margin-bottom: px2rem(45px);
          .left__line,
          .right__line {
            width: px2rem(66px);
            height: px2rem(8px);
          }
          .left__line {
            transform: rotateY(180deg);
          }
          span {
            font-size: px2rem(14px);
            font-weight: 600;
            color: #f0ff00;
            margin: 0 px2rem(8px);
          }
        }
        .total {
          font-size: px2rem(14px);
          font-weight: bold;
          padding: 0 px2rem(21px);
          margin-top: px2rem(34px);
          margin-bottom: px2rem(15px);
          span:nth-child(1) {
            color: #ffffff;
          }
          span:nth-child(2) {
            color: #fee34c;
          }
        }
      }
      .confirm-btn {
        margin-top: px2rem(22px);
      }
    }
    &-tabs {
      display: flex;
      justify-content: space-around;
      &-item {
        font-size: px2rem(12px);
        font-weight: 600;
        color: #7aa800;
        &.selected {
          color: #e2ff00;
        }
      }
    }
  }
}

.mirror .pg-invite {
  .detail {
    &-dialog {
      .body {
        .header {
          direction: ltr;
        }
      }
    }
  }
}
