@import '~@/common.scss';
@import '../../var.scss';

.#{$prefix-cls} .cmp-records {
  width: px2rem(369px);
  height: px2rem(365px);
  background-image: url(../../images/dialog.webp);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: px2rem(75px);
  box-sizing: border-box;

  &__title {
    margin: 0 auto;
    width: px2rem(185px);
    height: px2rem(30px);
    text-align: center;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 17%, #000000 52%, rgba(0, 0, 0, 0.85) 78%, rgba(0, 0, 0, 0) 100%);
    color: rgba(255, 220, 0, 1);
    font-size: px2rem(16px);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    margin-top: px2rem(12px);
    height: px2rem(220px);
    overflow-y: auto;
    padding: 0 px2rem(33px);
  }

  .cmp-record {
    width: 100%;
    height: 70px;
    padding: 0 px2rem(10px);
    box-sizing: border-box;
    border: px2rem(2px) rgba(255, 219, 0, 1) solid;
    border-radius: px2rem(3px);
    margin-bottom: px2rem(10px);
    display: flex;
    align-items: center;

    >img {
      width: px2rem(50px);
      height: px2rem(50px);
      margin-right: px2rem(10px);
    }

    &__info {
      display: flex;
      flex-direction: column;

      span:first-child {
        font-size: px2rem(14px);
        color: rgba(255, 220, 0, 1);
      }

      span:last-child {
        margin-top: px2rem(4px);
        font-size: px2rem(14px);
        color: rgba(193, 193, 193, 1);
      }
    }
  }
}

.mirror .#{$prefix-cls} .cmp-records {
  .cmp-record {
    >img {
      margin-left: px2rem(10px);
      margin-right: unset;
    }

    &__info {
      direction: ltr;
      text-align: right;
    }
  }
}