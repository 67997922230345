@import '~@/common.scss';
@import './var.scss';

.#{$prefix-cls} {
    position: relative;
    min-height: 100vh;
    background: #0C0538;
    padding-bottom: px2rem(50px);

    &__header {
        position: relative;
        margin-bottom: px2rem(20px);
        height: px2rem(500px);

        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-bottom: px2rem(-88px);
        }

        &-dm {
            position: absolute;
            top: px2rem(170px);
            left: 0;
            width: 100%;
            height: px2rem(150px);

            >div {
                width: 100%;
                height: 100%;
            }
        }

        &-records {
            width: px2rem(76px);
            height: px2rem(28px);
            background-image: url(./images/records.webp);
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            left: 0;
            top: px2rem(367px);
            color: rgba(255, 225, 85, 1);
            font-size: px2rem(14px);
            -webkit-text-stroke: 1px rgba(139, 46, 0, 0.30);
            padding: px2rem(6px) px2rem(12px) 0 0;
            box-sizing: border-box;

            span {
                display: block;
                text-align: center;
                font-weight: bold;
            }
        }

        &-rule {
            width: px2rem(61px);
            height: px2rem(28px);
            background-image: url(./images/rule.webp);
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            right: 0;
            top: px2rem(367px);
            color: rgba(255, 225, 85, 1);
            font-size: px2rem(14px);
            -webkit-text-stroke: px2rem(1px) rgba(139, 46, 0, 0.30);
            padding: px2rem(6px) 0 0 px2rem(12px);
            box-sizing: border-box;

            span {
                display: block;
                text-align: center;
                font-weight: bold;
            }
        }
    }

    &__game {
        position: relative;
        z-index: 1;
        width: px2rem(369px);
        height: px2rem(300px);
        display: flex;
        justify-content: center;
        background-image: url(./images/pane.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: px2rem(-75px) auto 0;
        padding: 0 px2rem(14px);
        box-sizing: border-box;

        &-remain {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: px2rem(31px);
            background-color: rgba($color: #000000, $alpha: .6);
            color: rgba(192, 192, 192, 1);
            border-radius: px2rem(33px);
            padding: px2rem(4px) px2rem(10px) px2rem(4px) px2rem(22px);

            strong {
                color: rgba(255, 220, 0, 1);
            }

            >img {
                width: px2rem(33px);
                height: px2rem(33px);
                position: absolute;
                bottom: 0;
                left: px2rem(-15px);
            }
        }
    }

    .cmp-game-task {
        margin: px2rem(10px) auto 0;
    }

    // .cmp-bottom-statement {
    //     font-weight: 400;
    //     color: rgba($color: #FFFFFF, $alpha: 0.6);
    // }

    // .cmp-tab-buttons {
    //     margin-top: px2rem(5px);

    //     &-btn {
    //         padding: unset;

    //         &__item {
    //             opacity: unset;
    //             background-image: url('./images/tab-unac.webp');
    //             background-size: contain;
    //             height: px2rem(41px);
    //             font-size: px2rem(14px);
    //             font-weight: bold;
    //             margin: unset;
    //             color: rgba(248, 227, 140, 0.6);
    //             background-size: cover;
    //         }

    //         &__text {
    //             font-size: px2rem(13px);
    //         }

    //         .selected {
    //             background-image: url('./images/tab-ac.webp');

    //             &>span {
    //                 color: rgba(248, 227, 140, 1);
    //             }
    //         }
    //     }

    // }

    // &__container {
    //     margin: px2rem(21px) px2rem(10px) 0;
    //     height: px2rem(666px);
    //     background: url('./images/list-bg.webp') no-repeat center/cover;
    //     position: relative;
    //     padding-top: px2rem(66px);
    //     box-sizing: border-box;

    //     &-title {
    //         position: absolute;
    //         left: 50%;
    //         transform: translateX(-50%);
    //         top: px2rem(22px);
    //         height: px2rem(18px);
    //         color: rgba(255, 232, 0, 1);
    //         font-size: px2rem(16px);
    //         font-style: italic;
    //         text-shadow: 0 px2rem(2px) px2rem(2px) rgba(66, 20, 10, 1);
    //         font-weight: bold;
    //     }
    // }
}

.mirror .#{$prefix-cls} {
    header>div {
        direction: ltr;
    }
}