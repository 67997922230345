@import '~@/common.scss';

.pg-charm-anchor .cmp-mine {
    width: 100%;
    height: px2rem(80px);
    position: fixed;
    bottom: 0;
    left: 0;
    background: url(~@CharmAnchor/images/user-bg.webp) no-repeat center/cover;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: px2rem(5px) px2rem(13px) 0;
    box-sizing: border-box;

    &>.left {
        display: flex;
        align-items: center;

        &>span:first-child {
            font-size: px2rem(16px);
            font-weight: bold;
            color: #FFFFFF;
        }

        &>div {
            display: flex;
            align-items: flex-end;
            margin: 0 px2rem(12px);

            .avatar {
                width: px2rem(40px);
                height: px2rem(40px);
                border: px2rem(1px) solid #FFC223;
                border-radius: 50%;
                object-fit: cover;
            }

            .target-avatar {
                width: px2rem(22px);
                height: px2rem(22px);
                border: px2rem(1px) solid #FFC223;
                border-radius: 50%;
                object-fit: cover;
                margin-right: px2rem(-10px);
            }
        }

        &>span:last-child {
            font-size: px2rem(14px);
            color: #FFFFFF;
        }
    }

    &>.right {
        display: flex;
        align-items: center;

        img {
            width: px2rem(11px);
            height: px2rem(11px);
            object-fit: cover;
            margin: 0 px2rem(4px);
        }

        span {
            font-size: px2rem(14px);
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}