@import '~@/common.scss';

.pg-recharge_send_medal .cmp-rewards {
    width: 100%;
    height: px2rem(1144px);
    background: url(~@RechargeSendMedal/images/rewards-bg.webp) no-repeat center/cover;
    position: relative;
    padding-top: px2rem(65px);
    box-sizing: border-box;

    &-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: px2rem(10px);
        font-size: px2rem(16px);
        font-weight: bold;
        color: #F9F2B4;
        text-shadow: 0px px2rem(2px) px2rem(2px) #813E09;
    }

    &-item {
        padding: 0 px2rem(10px);
        box-sizing: border-box;
        margin-bottom: px2rem(10px);
        flex-shrink: 0;

        &__title {
            width: px2rem(281px);
            height: px2rem(33px);
            background: url(~@RechargeSendMedal/images/title-bg.webp) no-repeat center/cover;
            font-size: px2rem(13px);
            font-weight: 500;
            color: #FFEC8C;
            display: flex;
            align-items: center;
            padding: 0 px2rem(11px);
            box-sizing: border-box;
        }

        &__content {
            display: flex;
            align-items: center;
        }

        .cmp-medal {
            &:nth-child(2n) {
                margin: 0 px2rem(4px);
            }
        }

        &__get {
            all: unset;
            width: px2rem(99px);
            height: px2rem(50px);
            background: url(~@RechargeSendMedal/images/get.webp) no-repeat center/cover;
            margin: 0 px2rem(9px);
        }

        .get-ac {
            background-image: url(~@RechargeSendMedal/images/get-ac.webp);
        }
    }
}

.mirror .pg-recharge_send_medal .cmp-rewards {
    &-item {
        &__title {
            transform: scaleX(-1);

            &>span {
                transform: scaleX(-1);
            }
        }
    }
}