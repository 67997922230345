body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  user-select: none;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide {
  display: none !important;
}

.pg {
  min-height: 100vh;
  height: 1px; /* 这里需要设定高度，否则会导致页面滑动卡顿 */
}

.pg.loading {
  width: 100vw;
  overflow: hidden;
}

.mirror {
  direction: rtl;
}

.mirror .adm-mask {
  direction: ltr;
}

.mirror .adm-mask .adm-toast-main-text {
  direction: rtl;
}
