@import '~@/common.scss';
@import '../../var.scss';

.#{$prefix-cls} .cmp-rewards-pool {
    margin: 0 px2rem(10px);
    background: url('../../images/rewards-pool-bg.webp') no-repeat center/cover;
    height: px2rem(220px);
    position: relative;
    margin-bottom: px2rem(26px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: px2rem(22px);
        color: rgba(255, 232, 0, 1);
        font-size: px2rem(16px);
        font-style: italic;
        text-shadow: 0 px2rem(2px) px2rem(2px) rgba(66, 20, 10, 1);
        font-weight: bold;
    }

    &__rewards {
        margin-top: px2rem(75px);
        display: flex;
        flex-direction: column;
        align-items: center;

        &>div {
            width: px2rem(95px);
            height: px2rem(94px);
            background: url('../../images/rewards-icon-bg.webp') no-repeat center/cover;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: px2rem(78px);
                height: px2rem(78px);
                object-fit: cover;
            }
        }

        &>span {
            font-weight: bold;
            font-size: px2rem(17px);
            color: #FFFFFF;
            margin-top: px2rem(7px);
        }
    }
}