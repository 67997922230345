@import '~@/common.scss';
@import '../../var.scss';

.#{$prefix-cls} .cmp-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  &__mask {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}