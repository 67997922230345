@import '@/common.scss';

.pg-national .event-gift {
    margin-top: px2rem(-27px);
    width: 100%;
    height: px2rem(465px);
    background-image: url(~@National/images/dialog-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

    &-box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: px2rem(95px);
        align-items: center;

        .title {
            color: #fff;
            font-size: bold;
            position: absolute;
            top: px2rem(70px);
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
            left: 50%;
            transform: translateX(-50%);
        }

        .spin-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .coin {
            display: flex;
            align-items: center;

            img {
                width: px2rem(10px);
                height: px2rem(10px);
                margin-right: px2rem(3px);
            }

            span {
                color: #FFDD00;
                font-size: px2rem(12px);
            }
        }

        .flag-box {
            width: 100%;
            display: flex;
            justify-content: center;

            .flag {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 px2rem(6px) px2rem(10px);

                .img-bg {
                    margin-top: px2rem(-14px);
                    width: px2rem(150px);
                    height: px2rem(129px);
                    display: flex;
                    justify-content: center;
                    background-image: url(~@National/images/flag-bg.webp);
                    background-repeat: no-repeat;
                    background-size: contain;

                    img {
                        margin-top: auto;
                        margin-bottom: px2rem(40px);
                        width: px2rem(75px);
                        height: px2rem(75px);
                        object-fit: cover;
                    }
                }

            }
        }


        .list {
            box-sizing: border-box;
            width: 100%;
            height: px2rem(185px);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 px2rem(8px);
            overflow: hidden;

            .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: px2rem(10px);

                .img-bg {
                    width: px2rem(61px);
                    height: px2rem(61px);
                    background-image: url(~@National/images/gift-item.webp);
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: px2rem(9px);

                    img {
                        width: px2rem(50px);
                        height: px2rem(50px);
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.mirror .pg-national .event-gift {
    .coin {
        img {
            margin-right: unset;
            margin-left: px2rem(3px);
        }
    }
}