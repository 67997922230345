@import '~@/common.scss';

.pg-invite .rank-item {
  background-image: url(~@Invite/images/rank-item-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  width: px2rem(348px);
  height: px2rem(60px);
  margin-bottom: px2rem(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  text-shadow: 0 px2rem(1px) 0 rgba(0, 0, 0, 0.5);
  &-left {
    display: flex;
    align-items: center;
    &-nick {
      font-size: px2rem(16px);
      font-weight: bold;
      color: #fff;
      span {
        width: px2rem(100px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      span:nth-child(2) {
        font-weight: 300;
      }
    }
    &-avatar {
      width: px2rem(50px);
      height: px2rem(50px);
      margin-right: px2rem(15px);
      border-radius: 50%;
      object-fit: cover;
    }
    &-sort {
      font-size: px2rem(16px);
      font-weight: bold;
      color: #fff;
      text-align: left;
      margin-left: px2rem(17px);
      width: px2rem(30px);
    }
  }
  &-right {
    height: px2rem(17px);
    font-size: px2rem(14px);
    font-weight: bold;
    color: #fff;
    line-height: px2rem(17px);
    display: flex;
    align-items: center;
    img {
      width: px2rem(14px);
      height: px2rem(14px);
      margin: 0 px2rem(5px);
    }
    span {
      font-size: px2rem(14px);
      margin-right: px2rem(13px);
    }
  }
}

.mirror .pg-invite {
  .rank-item {
    direction: rtl;

    &-left {
      &-sort {
        margin: unset;
        margin-left: px2rem(10px);
        margin-right: px2rem(21px);
      }
      &-avatar {
        margin-right: unset;
        margin-left: px2rem(15px);
      }
    }
    &-right {
      span {
        margin: unset;
        margin-left: px2rem(13px);
      }
    }
  }
}
