@import '~@/common.scss';

.pg-ramadan-answer .cmp-questions {
    @keyframes question-hide {
        0% {
            opacity: 1;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(0.5);
        }
    }

    @keyframes question-appear {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    .cmp-dialog {
        &__content {
            width: px2rem(360px);
            height: px2rem(289px);
            background-image: url(~@RamadanAnswer/images/questions-box-bg.webp);
            padding: px2rem(76px) px2rem(20px) 0;
            box-sizing: border-box;
            align-items: unset;
        }

        &__title {
            top: px2rem(25px);
            font-size: px2rem(16px);
            color: #7B3D00;
        }
    }

    &__dialog-content {
        display: flex;
        flex-direction: column;

        &>div {
            position: absolute;
            width: px2rem(214px);
            height: px2rem(215px);
            top: px2rem(37px);
            left: 50%;
            transform: translateX(-50%);
            background: url(~@RamadanAnswer/images/profile.webp) no-repeat center/cover;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: px2rem(82px);
                height: px2rem(82px);
                object-fit: contain;
            }
        }

        &>button {
            all: unset;
            width: px2rem(110px);
            height: px2rem(40px);
            background: url(~@RamadanAnswer/images/btn-ac.webp) no-repeat center/cover;
            font-weight: 600;
            font-size: px2rem(16px);
            color: #7B3D00;
            text-align: center;
            margin-top: auto;
            margin-bottom: px2rem(31px);
            z-index: 10;
        }
    }

    &__dally-rewards,
    &__answer-questions {
        position: relative;
        width: px2rem(360px);
        height: px2rem(289px);
        background: url(~@RamadanAnswer/images/questions-box-bg.webp) no-repeat center/cover;
        margin: 0 auto;
        margin-bottom: px2rem(20px);
        padding-top: px2rem(60px);
        box-sizing: border-box;

        &-title {
            font-size: px2rem(16px);
            color: rgba(123, 61, 0, 1);
            font-weight: 600;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: px2rem(25px);
        }

        &-btn {
            all: unset;
            width: px2rem(83px);
            height: px2rem(31px);
            font-weight: 600;
            font-size: px2rem(12px);
            color: #656565;
            background-image: url(~@RamadanAnswer/images/btn-unac.webp);
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            line-height: px2rem(31px);
        }

        .can-open {
            color: #7B3D00;
            background-image: url(~@RamadanAnswer/images/btn-ac.webp);
        }
    }

    &__dally-rewards {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-open-gift-box {
            width: px2rem(77px);
            height: px2rem(78px);
            object-fit: cover;
        }

        &-tip {
            margin-top: px2rem(7px);
            margin-bottom: px2rem(15px);
            font-weight: 600;
            font-size: px2rem(11px);
            color: #AC7C33;

            span {
                font-size: px2rem(15px);
                color: rgba(123, 61, 0, 1);
            }
        }

        &-progress {
            width: 100%;
            padding: 0 px2rem(36px);
            box-sizing: border-box;

            &-gifts {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: px2rem(42px);
                    height: px2rem(41px);
                    object-fit: cover;
                }
            }

            &-content {
                position: relative;
                margin: 0 px2rem(5px);
                box-sizing: border-box;
                overflow: hidden;

                .adm-progress-bar-trail {
                    height: px2rem(6px);
                    background: rgba(12, 10, 3, 0.3);
                    padding: px2rem(1px);
                    box-sizing: border-box;
                    border-radius: px2rem(3px);

                    .adm-progress-bar-fill {
                        height: px2rem(4px);
                        background: linear-gradient(180deg, #FFFF3D 0%, #FFDD38 48%, #FFAB1F 100%);
                        border-radius: px2rem(2px);
                    }
                }
            }

            &-num {
                margin-top: px2rem(3px);
                position: absolute;
                transition: left 0.3s;
                font-weight: 600;
                font-size: px2rem(11px);
                color: #7B3D00;
            }
        }
    }

    &__answer-questions {
        overflow: hidden;
        position: relative;

        &-content {
            display: flex;
            flex-direction: column;
            padding: 0 px2rem(20px);
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            min-height: px2rem(230px);
        }

        .question-hide {
            animation: question-hide 0.5s linear forwards;
        }

        .question-appear {
            opacity: 0;
            animation: question-appear 0.5s linear forwards;
            animation-delay: 0.5s;
        }

        &-sequence {
            font-weight: 600;
            font-size: px2rem(14px);
            color: #7B3D00;
            margin: px2rem(2px) auto px2rem(8px);
        }

        &-question {
            overflow-wrap: break-word;
            margin-bottom: px2rem(10px);
            font-weight: 600;
            font-size: px2rem(14px);
            color: #7B3D00;
        }

        &-btn {
            margin: 0 auto;
            margin-top: auto;
            margin-bottom: px2rem(20px);
        }

        &-select-item {
            display: flex;
            align-items: center;
            margin-bottom: px2rem(20px);

            &>div {
                width: px2rem(20px);
                height: px2rem(20px);
                background-image: url(~@RamadanAnswer/images/no-select.webp);
                background-repeat: no-repeat;
                background-size: cover;
            }

            &>span {
                margin: 0 px2rem(6px);
                font-weight: 600;
                font-size: px2rem(14px);
                color: #7B3D00;
            }
        }

        .selected {
            &>div {
                background-image: url(~@RamadanAnswer/images/select.webp);
            }
        }

        .right {
            &>div {
                background-image: url(~@RamadanAnswer/images/right.webp);
            }

            &>span {
                opacity: 0.5;
            }
        }

        .wrong {
            &>div {
                background-image: url(~@RamadanAnswer/images/wrong.webp);
            }

            &>span {
                opacity: 0.5;
            }
        }

        .selected.right,
        .selected.wrong {
            &>span {
                opacity: 1;
            }
        }
    }

    &__next-btn {
        all: unset;
        margin: 0 auto;
        width: px2rem(234px);
        height: px2rem(30px);
        background-color: rgba(197, 197, 197, 1);
        border-radius: px2rem(15px);
        font-weight: 500;
        font-size: px2rem(12px);
        color: #656565;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        img {
            width: px2rem(19px);
            height: px2rem(19px);
            object-fit: cover;
            margin: 0 px2rem(2px);
        }
    }

    .can-next {
        border: px2rem(2px) solid rgba(255, 197, 3, 1);
        background-image: linear-gradient(to bottom, rgba(255, 248, 149, 1), rgba(239, 123, 17, 1));
        color: #834201;
    }
}