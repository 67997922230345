@import '@/common.scss';

.pg-luckygift .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E4C330;
    margin-top: px2rem(19px);

    .title {
        font-size: px2rem(11px);
        margin: 0 px2rem(3px);
        font-weight: 600;
    }


    .time {
        width: px2rem(22px);
        height: px2rem(22px);
        background-image: url(~@LuckyGift/images/countdown-bg.webp);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: px2rem(16px);
        color: #fff;
        font-size: px2rem(12px);
    }

    .time:nth-child(2) {
        &::after {
            content: 'D';
            position: absolute;
            right: px2rem(-13px);
            color: #E4C330;
            font-weight: bold;
        }
    }

    .time:nth-child(3) {
        &::after {
            content: 'H';
            position: absolute;
            right: px2rem(-13px);
            color: #E4C330;
            font-weight: bold;
        }
    }

    .time:nth-child(4) {
        &::after {
            content: 'M';
            position: absolute;
            right: px2rem(-14px);
            color: #E4C330;
            font-weight: bold;
        }
    }

    .time:nth-child(5) {
        &::after {
            content: 'S';
            position: absolute;
            right: px2rem(-12px);
            color: #E4C330;
            font-weight: bold;
        }
    }
}

.mirror .pg-luckygift .countdown {
    .time {
        margin-right: unset;
        margin-left: px2rem(16px);
    }

    .time:nth-child(2) {
        &::after {
            right: unset;
            left: px2rem(-13px);
        }
    }

    .time:nth-child(3) {
        &::after {
            right: unset;
            left: px2rem(-13px);
        }
    }

    .time:nth-child(4) {
        &::after {
            right: unset;
            left: px2rem(-14px);
        }
    }

    .time:nth-child(5) {
        &::after {
            right: unset;
            left: px2rem(-12px);
        }
    }
}