@import '~@/common.scss';
@import '../../../var.scss';

.#{$prefix-cls} .cmp-game-box {
  width: 100%;
  height: px2rem(300px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__image {
    width: px2rem(110px);
    height: px2rem(97px);
    object-fit: fill;
  }

  &__text {
    width: px2rem(91px);
    height: px2rem(30px);
    object-fit: fill;
  }
}