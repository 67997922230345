@import '~@/common.scss';

.pg-ramadan-answer .cmp-rule {
    position: absolute;
    width: px2rem(71px);
    height: px2rem(33px);
    top: px2rem(187px);
    right: 0;

    &-btn {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: px2rem(12px);
        color: #7B3D00;
        background: url(~@RamadanAnswer/images/rule-bg.webp) no-repeat center/cover;
    }

    .cmp-dialog {
        &__content {
            width: px2rem(360px);
            height: px2rem(429px);
            background-image: url(~@RamadanAnswer/images/dialog-bg.webp);
            padding: px2rem(76px) px2rem(20px) 0;
            box-sizing: border-box;
            align-items: unset;

            .text {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                overflow-y: auto;

                span {
                    margin-bottom: px2rem(20px);
                    font-weight: 500;
                    font-size: px2rem(12px);
                    color: #7B3D00;

                    &:last-child {
                        margin-bottom: unset;
                    }
                }
            }
        }

        &__title {
            top: px2rem(25px);
            font-size: px2rem(16px);
            color: #7B3D00;
        }

        &__close {
            top: 0;
            right: 0;
        }
    }

}

.mirror .pg-ramadan-answer .cmp-rule {
    right: unset;
    left: 0;

    &-btn {
        transform: scaleX(-1);

        span {
            transform: scaleX(-1);
        }

    }

    .cmp-dialog {

        &__content {
            .text {
                direction: rtl;
            }
        }
    }
}