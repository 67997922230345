@import '~@/common.scss';

.pg-ramadan-answer {
    position: relative;
    min-height: 100vh;
    background: #BE973F;

    &__banner {
        width: 100%;
        height: px2rem(352px);
        object-fit: cover;
    }

    .cmp-bottom-statement {
        font-weight: 400;
        color: rgba($color: #FFFFFF, $alpha: 0.6);
    }

    .cmp-tab-buttons {
        margin-top: px2rem(5px);

        &-btn {
            padding: 0 px2rem(2px);

            &__item {
                opacity: unset;
                background-image: url(~@RamadanAnswer/images/tab-unac.webp);
                background-size: contain;
                height: px2rem(41px);
                font-size: px2rem(14px);
                font-weight: bold;
                color: rgba(248, 227, 140, 0.6);
                margin: 0 px2rem(1px);
            }

            &__text {
                margin-bottom: px2rem(4px);
            }

            .selected {
                background-image: url(~@RamadanAnswer/images/tab-ac.webp);

                &>span {
                    color: rgba(248, 227, 140, 1);
                }
            }
        }

        // &-children {
        //     background-image: url(~@RamadanAnswer/images/rank-bg.webp);
        //     height: px2rem(872px);
        //     background-size: 100% 100%;
        //     margin-top: px2rem(13px);
        //     padding: 0 px2rem(22px);
        //     padding-top: px2rem(80px);
        //     position: relative;
        // }
    }
}