@import '~@/common.scss';
@import '../../var.scss';

.#{$prefix-cls} .cmp-rule {
    width: px2rem(369px);
    height: px2rem(365px);
    background-image: url(../../images/dialog.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: px2rem(75px);
    box-sizing: border-box;

    &__title {
        margin: 0 auto;
        width: px2rem(185px);
        height: px2rem(30px);
        text-align: center;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 17%, #000000 52%, rgba(0, 0, 0, 0.85) 78%, rgba(0, 0, 0, 0) 100%);
        color: rgba(255, 220, 0, 1);
        font-size: px2rem(16px);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__description {
        margin-top: px2rem(15px);
        font-size: px2rem(14px);
        color: white;
        padding: 0 px2rem(30px);
        display: flex;
        flex-direction: column;

        span {
            margin-bottom: px2rem(15px);
        }
    }
}